import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Typography,


} from '@mui/material'

import { app } from '../../firebase'
import {getAuth,onAuthStateChanged} from "firebase/auth";



const auth =getAuth(app)


const HomePage = () => {


  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const navigate = useNavigate();



  window.addEventListener('pageshow', (event) => {
    if (event.persisted) { // Check if the page was restored from history
        window.location.reload(true);
    }
});


  const handelVideoButtonClick =()=>{
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user){
        navigate("/room/video-chat")
      }else{
       
          navigate("/create-account")
    
      }
  
      // Unsubscribe after the initial authentication state check
      unsubscribe();
    }, [auth]);
  
  }


  const handelTextButtonClick =()=>{
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user){
        navigate("/room/text-chat")
      }else{
       
          navigate("/create-account")
    
      }
  
      // Unsubscribe after the initial authentication state check
      unsubscribe();
    }, [auth]);
  
  }
  




  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);


 

  return (
    <Box sx={{ width: "100%", height: "100%",overflow:"hidden",backgroundColor:"#253545"}}>

        <Box sx={{ width: "100%", height: "100%",overflow:"hidden",backgroundColor:"#253545",position:'relative',   
    "@media screen and (max-height: 600px)": {
      overflowY:'auto'
      },}}>
{
<img src='home_bg.jpg' alt='home_bg' style={{width:"100%",height:"100%",filter:"blur(10px)"}}/>
 }

<Box sx={{
  width:"500px",
  boxShadow:10,
  borderRadius:"15px",
  height:"calc(100% - 50px)",
  position:"absolute",
  top:"25px",
  left:"calc(50% - 250px)",
  "@media screen and (max-width: 600px)": {
    width:"430px",
    left:"calc(50% - 215px)",
   },
   "@media screen and (max-width: 450px)": {
    width:"340px",
    left:"calc(50% - 170px)",
   },
   "@media screen and (max-width: 350px)": {
    width:"300px",
    left:"calc(50% - 150px)",
   },
   "@media screen and (max-height: 600px)": {
    height:"500px"
    },
  }}>

<Box style={{
  width:"80%",
  paddingTop:"30px",
  marginTop:"25px",
  paddingBottom:"20px",
  backgroundColor:"#354555",
  color:"white",
  textAlign:'center',
  fontSize:"30px",
  fontWeight:'bold',
  marginLeft:"10%",
  borderRadius:"15px",
  boxShadow:5,
  "@media screen and (max-height: 800px)": {
   paddingTop:"10px",
   paddingBottom:"10px",
   },
 
  }}>
  <img style={{width:"200px",height:"40px"}} src='rimiex-logo-name-img.png'/> 
  </Box>

<Typography sx={{
  color:"white",
  fontSize:"40px",
  textAlign:'center',
  marginTop:"20px",
  "@media screen and (max-height: 800px)": {
    fontSize:"30px"
    },
  }}>Welcome to Rimiex</Typography>

  <Typography sx={{
    color:"white",
    fontSize:"25px",
    textAlign:'center',
    marginBottom:"50px",
    "@media screen and (max-height: 800px)": {
      fontSize:"18px"
      },
    }}>Talk with random peoples</Typography>
  


 
   <Button variant="contained" sx={{
    width:"80%",
    height:"50px",
    fontSize:"18px",
    fontWeight:"bold",
    marginLeft:"10%",
    marginBottom:"30px",
    "@media screen and (max-width: 600px)": {
      height:"40px",
      marginBottom:"20px"
     },
  }} onClick={handelVideoButtonClick}>Start video chat</Button>
  
 

   <Button color='secondary' variant="contained" sx={{
    width:"80%",
    height:"50px",
    fontSize:"18px",
    fontWeight:"bold",
    marginLeft:"10%",
    marginBottom:"30px",
    "@media screen and (max-width: 600px)": {
      height:"40px"
     },
   }} onClick={handelTextButtonClick}>Start text chat</Button>
   
 
 <Typography sx={{width:"calc(100% - 20px)",textAlign:'center',color:"white",position:"absolute",bottom:20,left:10,fontSize:"17px"}}>By continuing you are agree to our <Link to={"/terms-and-conditions"} style={{color:"skyblue"}}>Terms of Conditions</Link> and <Link to={'/privacy-policy'} style={{color:"skyblue"}}>Privacy Policy</Link> make sure you are 18+ years old.</Typography>
</Box>




</Box>


    </Box>
  )
}

export default HomePage

