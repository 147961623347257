import React, { useEffect, useState,useRef } from 'react'
import {
    Box,
    Grid,
    Button,
    IconButton,
    OutlinedInput,
    Typography
} from '@mui/material'
import {Send } from '@mui/icons-material'
import MyVideoLoader  from '../Other/MyVideoLoader';
import AlertForPlan from '../Other/AlertForPlan';
import { useNavigate } from 'react-router-dom'
import MyLoader from '../Other/MyLoader';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from '../../firebase'
import {
    collection,
    getDocs,
    getDoc,
    setDoc,
    doc,
    getFirestore,
    where,
    query,
    updateDoc,
    onSnapshot,
    deleteDoc,
    addDoc
} from 'firebase/firestore';


const auth = getAuth(app);
const dbs = getFirestore(app)

const TextChatPage = () => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showLoader, setShowLoader] = useState(true)
    const [showPlanAlert, setShowPlanAlert] = useState(false)
    const [enableButton, setEnableButton] = useState(true)
    const [inputMessage, setInputMessage] = useState('')
    const [userCurrentPlan, setUserCurrentPlan] = useState("Free")
    const [callLimites, setCallLimites] = useState()
    const [newRoomId, setNewRoomId] = useState("")
    const [showLoaderForTextChat, setShowLoaderForTextChat] = useState(null)
    const [receivedMessages, setReceivedMessages] = useState([])
    const [connectionStatus, setConnectionStatus] = useState('')
    const chatBoxContainerRef = useRef(null);

     //timeing state
     var timer
     const [seconds, setSeconds] = useState(0);
     const [minutes, setMinutes] = useState(0);
     const [hours, setHours] = useState(0)

    const navigate = useNavigate();

    const pc = useRef();
    const dataChannel = useRef();

    const servers = {
        iceServers: [
            {
                urls: [
                    'stun:stun1.l.google.com:19302',
                    'stun:stun2.l.google.com:19302',
                    'stun:global.stun.twilio.com:3478'
                ],
            },
        ],
        iceCandidatePoolSize: 10,
    };

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [screenWidth]);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {

                setTimeout(() => {

                     deleteRoom(auth.currentUser.uid).then(() => {
                     checkUserCurrentPlan(auth.currentUser.uid)
                    //setShowLoader(false)
                    })


                }, 2000)

            } else {
                navigate('/create-account');


            }

            // Unsubscribe after the initial authentication state check
            unsubscribe();
        }, [auth]);

        // Clean up the subscription when the component unmounts
        return () => {
            unsubscribe();
        };
    }, [navigate]);


    const checkUserCurrentPlan = async (currentUserId) => {

        try {
            const querySnapshot = await getDocs(collection(dbs, "UserBilling", currentUserId, "activePlan"));
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {

                    if (doc.data().status === "active") {
                        
                        checkCallLimite(auth.currentUser.uid, doc.data().planName)
                        
                    } else {  
                        checkCallLimite(auth.currentUser.uid, "Free") 
                    }

                });
            } else { 
                checkCallLimite(auth.currentUser.uid, "Free")
            }

        } catch (error) {
            console.log("Error fetching user bill data", error);
        }
    }



    const checkCallLimite = async (currentUserId, userPlan) => {
        if (userPlan ==="Free") {
            const now = new Date();
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const dateString = now.toLocaleDateString(undefined, options);
            try {

                const databaseRef = doc(dbs, "CallLimites", currentUserId);
                const mycollection = collection(databaseRef, 'data')

                const docRef = query(mycollection, where("date", "==", dateString.toString()));
                const querySnapshot = await getDocs(docRef)

                if (!querySnapshot.empty) {
                    const firstDocument = await querySnapshot.docs[0];
                    setCallLimites(firstDocument.data().limitLeft)

                    if (firstDocument.data().limitLeft <= 0) {
                        // show plan alert
                        console.log(userPlan)
                        setShowPlanAlert(true)
                        setCallLimites(firstDocument.data().limitLeft)
                        setUserCurrentPlan(userPlan)
                    } else {
                        // start web cam for have call limit
                        console.log(userPlan)
                        console.log("have call limite")
                        startPeerConnection().then(()=>{
                            setUserCurrentPlan(userPlan)
                            setCallLimites(firstDocument.data().limitLeft)
                            setShowLoader(false)
                            setShowLoaderForTextChat(true)
                            findTextRoom()
                        })
                       
                    }


                } else {


                    await setDoc(doc(mycollection, "today"), {
                        date: dateString,
                        limitLeft: 25
                    }).then(() => {
                        // start web cam for call limit stored
                        console.log(userPlan)
                       console.log("limit stored")
                       startPeerConnection().then(()=>{
                        setUserCurrentPlan(userPlan)
                        setCallLimites(25)
                        setShowLoader(false)
                        setShowLoaderForTextChat(true)
                        findTextRoom()
                    })
                       

                    }).catch((error) => {
                        console.log(error)
                    })

                }

            } catch (error) {
                console.log("Error from checking call limite : " + error)
            }
        } else {
            // start web cam for unlimited call
            console.log(userPlan)
console.log("unlimited call")
startPeerConnection().then(()=>{
    setUserCurrentPlan(userPlan)
    setShowLoader(false)
    setShowLoaderForTextChat(true)
    findTextRoom()
})
           
        }
    }

const storeCallLimite=async(currentUserId,plan)=>{
    if(plan==="Free"){
        try {

            const databaseRef = doc(dbs, "CallLimites", currentUserId);
                const mycollection = collection(databaseRef, 'data')
                if(!mycollection.empty){
const data= await getDoc(doc(mycollection,"today"))
                await updateDoc(doc(mycollection,"today"),{
limitLeft:data.data().limitLeft-1,
                }).then(()=>{
                    setCallLimites(data.data().limitLeft-1)
                })
            }
        } catch (error) {
          console.log("Error from store call limite : "+error)  
        }
    }
}

const startPeerConnection=async()=>{
    try {
        pc.current = new RTCPeerConnection(servers);

        dataChannel.current = pc.current.createDataChannel('text-chat');
            pc.current.addEventListener('datachannel', event => {
                dataChannel.current = event.channel;
            });

            configureDataChannel()

    } catch (error) {
     console.log("Error from stat peer connection")   
    }
}


const configureDataChannel = () => {
    // Set up event listeners for the data channel
    dataChannel.current.onopen = console.log("data channel is open");
    dataChannel.current.onmessage = handleDataChannelMessage;
    dataChannel.current.onclose = console.log("data channel is close")

    // ... Other data channel configurations
};

const handleDataChannelMessage = (event) => {
    // Handle incoming messages on the data channel

    setReceivedMessages((prevMessages) => [...prevMessages, { sender: 'Other', text: event.data }]);




    //scrollToBottomInChat()
    // You can update your state or perform other actions based on the received message
};

const sendMessageUsingDataChannel = (myMessage) => {
    if (dataChannel.current.readyState === 'open') {
        // Send the message over the data channel
        if (inputMessage !== '') {
            setReceivedMessages((prevMessages) => [...prevMessages, { sender: 'You', text: inputMessage }])
            dataChannel.current.send(myMessage);
            setInputMessage('')

        }
    } else {
        console.warn('Data channel is not open.');
        setReceivedMessages([])
        setSeconds(0)
        setMinutes(0)
        setHours(0)
    }
};

const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the form submission logic here with the inputValue
   sendMessageUsingDataChannel(inputMessage)
  };

const findTextRoom = async () => {
    try {

        const querys = query(collection(dbs, "TextRooms"),
            where("callerId", "!=", auth.currentUser.uid),
            where("status", "==", "free"))

        const querySnapshot = await getDocs(querys)

        if (!querySnapshot.empty) {
            const firstDocument = await querySnapshot.docs[0];
            //joinVideoRoom(firstDocument.data().callerId)
            joinTextRoom(firstDocument.data().callerId)


        } else {

           // createVideoRoom()
           createTextRoom()
        }
    } catch (error) {
        console.error("Error in findVideoRoom:", error);
    }
};

const createTextRoom = async () => {

    try {


        // database ref
        const channelDoc = doc(dbs, "TextRooms", auth.currentUser.uid);
        const offerCandidates = collection(channelDoc, 'offerCandidates');
        const answerCandidates = collection(channelDoc, 'answerCandidates');

        setNewRoomId(auth.currentUser.uid);
        // ice candidate adding
        pc.current.onicecandidate = async event => {
            try {
                if (event.candidate) {
                    await addDoc(offerCandidates, { data: event.candidate.toJSON() });
                    console.log("ice")
                }
            } catch (error) {
                console.log(error)
            }

        };


        // creating offer
        const offerDescription = await pc.current.createOffer();
        pc.current.setLocalDescription(offerDescription);

        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type,
        };

        // seting database with offer and caller id
        await setDoc(channelDoc, {
            offer: offer,
            status: 'free',
            callerId: auth.currentUser.uid
        }).then(() => { console.log("offer stored") });


        // geting answer from database and adding to remote description
        onSnapshot(channelDoc, async snapshot => {
            try {
                const data = snapshot.data();
                if (!pc.current.currentRemoteDescription && data?.answer) {
                    try {
                        const answerDescription = new RTCSessionDescription(data.answer);
                        pc.current.setRemoteDescription(answerDescription);
                    } catch (error) {
                        console.log(error)
                    }

                }
            } catch (error) {
                console.log(error)
            }

        });

        // When answered, add candidate to peer connection
        onSnapshot(answerCandidates, snapshot => {
            snapshot.docChanges().forEach(change => {
                if (change.type === 'added') {
                    const candidateData = change.doc.data();
                    const candidate = new RTCIceCandidate(candidateData.data);
                    pc.current.addIceCandidate(candidate)
                        .then(() => {
                            console.log('Ice candidate added successfully');
                        })
                        .catch(error => {
                            console.error('Error adding ice candidate:', error);
                        });
                }
            });
        });



        // cheking connection state change
        pc.current.onconnectionstatechange = async (event) => {
            try {
                console.log(event.target.connectionState)
                if (event.target.connectionState === "connected") {
                   
                    setEnableButton(false)
                    setConnectionStatus("connected")
                    setSeconds(0)
                    setMinutes(0)
                   setHours(0)
                   setReceivedMessages([])
                    setShowLoaderForTextChat(false)
                    storeCallLimite(auth.currentUser.uid,userCurrentPlan)

                }
                if (event.target.connectionState === "failed") {
                   
                    setEnableButton(true)
                    setConnectionStatus("failed")
                    setSeconds(0)
                   setMinutes(0)
                   setHours(0)
                    setReceivedMessages([])
                    window.location.reload();

                }
                if (event.target.connectionState === "disconnected") {
                   
                    setConnectionStatus("disconnected")
                    setSeconds(0)
                    setMinutes(0)
                    setHours(0)
                    setEnableButton(true)
                   
                    pc.current.close()
                    setReceivedMessages([])
                    setShowLoaderForTextChat(true)
                    checkUserCurrentPlan(auth.currentUser.uid)

                }
            } catch (error) {
                console.log(error)
            }


        }

    } catch (error) {
        console.log("Error from start call : " + error)
    }
}



const joinTextRoom = async (roomId) => {
    try {

        // database ref
        const channelDoc = doc(dbs, "TextRooms", roomId);
        const offerCandidates = collection(channelDoc, 'offerCandidates');
        const answerCandidates = collection(channelDoc, 'answerCandidates');

        setNewRoomId(roomId);

        // ice candidate adding to database
        pc.current.onicecandidate = async event => {
            try {
                if (event.candidate) {
                    await addDoc(answerCandidates, { data: event.candidate.toJSON() })
                }
            } catch (error) {
                console.log(error)
            }

        };


        // geting offer from database and adding to Remote Description
        const channelDocument = await getDoc(channelDoc);
        const channelData = channelDocument.data();
        const offerDescription = channelData.offer;
        pc.current.setRemoteDescription(
            new RTCSessionDescription(offerDescription),
        );

        // creating andwer
        const answerDescription = await pc.current.createAnswer();
        pc.current.setLocalDescription(answerDescription);

        const answer = {
            type: answerDescription.type,
            sdp: answerDescription.sdp,
        };

        // updating database with answer and joiner id
        await updateDoc(channelDoc, {
            answer: answer,
            status: 'busy',
            joinerId: auth.currentUser.uid
        }).then(() => { console.log("answer stored") })


        // When answered, add candidate to peer connection
        onSnapshot(offerCandidates, snapshot => {
            snapshot.docChanges().forEach(change => {
                if (change.type === 'added') {
                    const candidateData = change.doc.data();
                    const candidate = new RTCIceCandidate(candidateData.data);
                    pc.current.addIceCandidate(candidate)
                        .then(() => {
                            console.log('Ice candidate added successfully');
                        })
                        .catch(error => {
                            console.error('Error adding ice candidate:', error);
                        });
                }
            });
        });


        // cheking connection state change
        pc.current.onconnectionstatechange = async (event) => {
            try {
                console.log(event.target.connectionState)
                if (event.target.connectionState === "connected") {
                    
                    setEnableButton(false)
                    setConnectionStatus("connected")
                    setSeconds(0)
                    setMinutes(0)
                    setHours(0)
                    setReceivedMessages([])
                    setShowLoaderForTextChat(false)
                    storeCallLimite(auth.currentUser.uid,userCurrentPlan)
                }
                if (event.target.connectionState === "failed") {
                    setEnableButton(true)
                    setConnectionStatus("failed")
                    setSeconds(0)
                    setMinutes(0)
                    setHours(0)
                    setReceivedMessages([])
                    window.location.reload();

                }
                if (event.target.connectionState === "disconnected") {
                    setConnectionStatus("disconnected")
                    setSeconds(0)
                    setMinutes(0)
                    setHours(0)
                    setEnableButton(true)
                    pc.current.close()
                    setReceivedMessages([])
                    setShowLoaderForTextChat(true)
                   checkUserCurrentPlan(auth.currentUser.uid)

                }
            } catch (error) {
                console.log(error)
            }


        }
    } catch (error) {
        console.log("Error from join call : " + error)
    }

}


const deleteRoom = async (roomId) => {


    try {
        const parentDocumentId = roomId ? roomId : newRoomId;
        const parentDocRef = doc(dbs, 'TextRooms', parentDocumentId);
        const nestedCollectionRef1 = collection(parentDocRef, 'offerCandidates');
        const nestedCollectionRef2 = collection(parentDocRef, 'answerCandidates');

        if (nestedCollectionRef1) {
            const nestedCollectionSnapshot1 = await getDocs(nestedCollectionRef1);
            nestedCollectionSnapshot1.forEach(async (nestedDoc) => {
                await deleteDoc(nestedDoc.ref);
            });
        }

        if (nestedCollectionRef2) {
            const nestedCollectionSnapshot2 = await getDocs(nestedCollectionRef2);
            nestedCollectionSnapshot2.forEach(async (nestedDoc) => {
                await deleteDoc(nestedDoc.ref);
            });
        }

        if (parentDocRef) {
            await deleteDoc(parentDocRef);
        }


        // Reset the New Room ID
        setNewRoomId("");

        console.log('Document deleted successfully');
    } catch (error) {
        console.error('Error deleting document:', error);
    }
}

useEffect(() => {
    if (connectionStatus === "connected") {
        timer = setInterval(() => {
            setSeconds(seconds + 1)
            if (seconds === 59) {
                setMinutes(minutes + 1)
                setSeconds(0)
            }
            if (minutes === 59 && seconds === 59) {
                setHours(hours + 1)
                setMinutes(0)
                setSeconds(0)
            }
        }, 1000)
    } else {
        setHours(0)
        setMinutes(0)
        setSeconds(0)
    }
    return () => clearInterval(timer)
})

useEffect(() => {
    if (chatBoxContainerRef.current) {
        chatBoxContainerRef.current?.scrollIntoView({ behavior: "smooth" })
    }

}, [receivedMessages])

const onSkipButtonClick = async () => {
    setEnableButton(true)
    try {
        setShowLoaderForTextChat(true)
        
        pc.current.close()
        setConnectionStatus("disconnected")
        setReceivedMessages([])
        setSeconds(0)
        setMinutes(0)
        setHours(0)
        deleteRoom().then(() => {
            checkUserCurrentPlan(auth.currentUser.uid)
        })
    }

    catch (error) {
        console.log(error)
    }
}

const onStopButtonClick = async () => {
    setShowLoader(true)
    deleteRoom().then(() => {

        navigate('/', { replace: true });
        window.location.reload();
    })

}


const handelPlanAlertCancelButton=()=>{
    navigate('/')
  }
  
  const handelPlanAlertUpgreadeButton=()=>{
    navigate("/pricing")
  }
  
    return (
        <Box sx={{ width: "100%", height: "100%", backgroundColor: "#253545", }}>
            <Grid container sx={{
                width: "calc(100% - 20px)",
                height: "calc(100% - 20px)",
                margin: "10px",
                padding: "5px"
            }} >
                <Box sx={{
                    width: "100%",
                    height: "50px",
                    border: "0.5px solid skyblue",
                    marginBottom: "5px",
                    display: 'none',
                    "@media screen and (max-width: 750px)": {
                        display: 'block',

                    }
                }}>
                    
                        <div style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color:connectionStatus==="connected"?"lightgreen": "red", marginLeft: "15px", fontSize: "18px", width: "50%", textAlign: "left",fontWeight:"bold" }}>{connectionStatus}</Typography>
                            {
                                userCurrentPlan ==="Free"?
                                <Typography sx={{ float: 'right', width: "50%", textAlign: "right", marginRight: "15px", fontSize: "18px", color: "gold" }}>Left : {callLimites}</Typography>
                                :<Typography sx={{ float: 'right', width: "50%", textAlign: "right", marginRight: "15px", fontSize: "18px", color: "gold" }}>Unlimited</Typography>
                               }
                        </div>
                       
                    
                </Box>
                <Box sx={{
                    width: "100%",
                    height: "calc(100% - 83px)",
                    overflow: "hidden",
                    marginBottom: "10px",
                    "@media screen and (max-width: 750px)": {
                        height: "calc(100% - 123px)",
                        marginBottom:"5px"

                    }

                }}>

                    <Box sx={{
                        border: "0.5px solid skyblue",
                        width: "350px",
                        height: "calc(100% - 2px)",
                        float: "left",
                        display: 'block',
                        "@media screen and (max-width: 1100px)": {
                            width: "300px"

                        },
                        "@media screen and (max-width: 900px)": {
                            width: "250px"

                        },
                        "@media screen and (max-width: 750px)": {
                            display: "none",

                        }
                    }}>
                        
                            <Typography sx={{ color:connectionStatus==="connected"?"lightgreen": "red",marginTop:"20px",marginBottom:"15px", fontSize: "25px", width: "100%", textAlign: "center",fontWeight:"bold" }}>{connectionStatus}</Typography>
                            {
                                userCurrentPlan ==="Free"?
                                <Typography sx={{  width: "100%", textAlign: "center", fontSize: "18px", color: "gold" }}>Connection left : {callLimites}</Typography>
                                :<Typography sx={{  width: "100%", textAlign: "center", fontSize: "18px", color: "gold" }}>Unlimited connection</Typography>
                               }
                               <Typography sx={{ color: "red", marginTop: "15px", fontSize: "18px", width: "100%", textAlign: "center" }}>{hours < 10 ? "0" + hours : hours} : {minutes < 10 ? "0" + minutes : minutes} : {seconds < 10 ? "0" + seconds : seconds}</Typography>
                      
                    </Box>
                    <Box sx={{
                        border: "0.5px solid skyblue",
                        width: "calc(100% - 362.2px)",
                        height: "calc(100% - 2px)",
                        marginLeft: "9px",
                        float: 'left',
                        "@media screen and (max-width: 1100px)": {
                            width: "calc(100% - 312.2px)"

                        },
                        "@media screen and (max-width: 900px)": {
                            width: "calc(100% - 262.2px)"

                        },
                        "@media screen and (max-width: 750px)": {
                            width: "calc(100% - 2px)",
                            marginLeft: "0px",
                        }
                    }}>
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                            overflowY: 'auto',
                            position:'relative',
                            "@media screen and (max-width: 750px)": {
                                height: "calc(100% - 55px)"

                            }
                        }}>
                            
                            {
                                showLoaderForTextChat?<MyVideoLoader />:null
                            }
                            {
                                connectionStatus==="connected"?
                                <Typography sx={{ color: "greenyellow", fontSize: "18px", textAlign: 'center', marginTop: "10px" }}>Chat Room</Typography>
                                :null
                            }
 
                            {receivedMessages.map((msg, index) => (
                                <ul key={index}>
                                    <li style={{ color: "white" }}><strong style={{ color: msg.sender === "Other" ? "red" : "gold" }}>{msg.sender}: </strong> {msg.text}</li>
                                </ul>
                            ))}
                            <div ref={chatBoxContainerRef} />
                            
                        </Box>
                        <form onSubmit={handleSubmit}>
                        <Box sx={{
                            width:"100% ",
                            height:"50px",
                            borderTop:"0.5px solid skyblue",
                            marginTop:"4.5px",
                            display:'none',
                            "@media screen and (max-width: 750px)": {
                                display:'block'

                            }
                            }}>
                                
                                 <OutlinedInput
                                 disabled={enableButton}
                                placeholder='Enter messages'
                                sx={{
                                    float: "left",
                                    backgroundColor: "white",
                                    color: "black",
                                    margin: "10px",
                                    height: "calc(100% - 20px)",
                                    width: "calc(100% - 93px)"
                                }}
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                
                            />
                           
                            <Box sx={{ float: "left", backgroundColor: "skyblue", marginTop: "10px", marginBottom: "10px", marginRight: "10px", height: "calc(100% - 20px)", width: "63px", borderRadius: "5px", display: "flex", justifyContent: 'center' }}>
                                <IconButton onClick={() => sendMessageUsingDataChannel(inputMessage)} disabled={enableButton}  sx={{ height: "100%", width: "100%", color: "white" }}><Send /></IconButton>
                            </Box>
                            </Box>
                            </form>

                    </Box>

                </Box>
                <form style={{width:"100%"}} onSubmit={handleSubmit}>
                <Box sx={{
                    width: "100%",
                    height: "70px",
                    border: "0.5px solid skyblue",
                    marginBottom: "10px",
                    "@media screen and (max-width: 750px)": {
                        height: '60px'

                    }
                }}>


                    <Box sx={{
                        float: "left",
                        backgroundColor: "#575757",
                        margin: "10px",
                        height: "calc(100% - 20px)",
                        width: "90px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: 'center',
                        "@media screen and (max-width: 750px)": {
                            width: "calc(50% - 15px)"

                        }
                    }}>
                        <Button onClick={onSkipButtonClick} disabled={enableButton} sx={{ height: "100%", width: "100%", color: "white" }}>Skip</Button>
                    </Box>

                    <Box sx={{
                        float: "left",
                        backgroundColor: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                        height: "calc(100% - 20px)",
                        width: "90px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: 'center',
                        "@media screen and (max-width: 750px)": {
                            width: "calc(50% - 15px)",


                        }
                    }}>
                        <Button onClick={onStopButtonClick} disabled={enableButton} sx={{ height: "100%", width: "100%", color: "white" }} >Stop</Button>
                    </Box>

                    <OutlinedInput 
                    disabled={enableButton}
                    placeholder='Enter messages'
                     sx={{
                        float: "left",
                        backgroundColor: "white",
                        color: "black",
                        margin: "10px",
                        height: "calc(100% - 20px)",
                        width: "calc(100% - 320px)",
                        display: "block",
                        "@media screen and (max-width: 750px)": {
                            display: "none",

                        }

                    }}
                    value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                    />

                    <Box sx={{
                        float: "left",
                        backgroundColor: "skyblue",
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginRight: "10px",
                        height: "calc(100% - 20px)",
                        width: "90px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: 'center',

                        "@media screen and (max-width: 750px)": {
                            display: "none",

                        }
                    }}>
                        <IconButton onClick={() => sendMessageUsingDataChannel(inputMessage)} disabled={enableButton} sx={{ height: "100%", width: "100%", color: "white" }}><Send /></IconButton>
                    </Box>


                </Box>
                </form>
            </Grid>
            
            {showLoader ? <MyLoader /> : null}
            {showPlanAlert ? <AlertForPlan upgradeFun={handelPlanAlertUpgreadeButton} cancelFun={handelPlanAlertCancelButton} bigText={"Chat limit over"} smallText={"Your daily chat limit has crossed for today. Please upgread your plan for unlimited chats or wait for tomorrow."}/> : null}
        </Box>
    )
}

export default TextChatPage