import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  SwipeableDrawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,


} from '@mui/material'
import "material-icons/iconfont/material-icons.css";

import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { app } from './firebase'
import { Menu } from '@mui/icons-material'

import menuList from './json-api/menu-list.json'
import HomePage from './components/Home/HomePage';
import VideoChatPage from './components/VideoChat/VideoChatPage';
import CreateAccount from './components/CreateAccount/CreateAccount';
import { useNavigate } from 'react-router-dom'
import Profile from './components/Profile/Profile';
import MyLoader from './components/Other/MyLoader';
import MyAlert from './components/Other/MyAlert';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import Pricing from './components/Pricing/Pricing';
import Billing from './components/Billing/Billing';
import SignIn from './components/CreateAccount/SignIn';
import Login from './components/CreateAccount/Login';
import ForgotPassword from './components/CreateAccount/ForgotPassword';
import TextChatPage from './components/TextChat/TextChatPage';
import HelpAndSupport from './components/HelpAndSupport/HelpAndSupport';








const auth = getAuth(app)

const App = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showLogOutAlert, setShowLogOutAlert] = useState(false)
  const [isLogin, setIsLogin] = useState(null)
  const [userPhotoUrl, setUserPhotoUrl] = useState("")


  const { pathname } = useLocation()

  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserPhotoUrl(user.photoURL)
        setIsLogin(true)
      } else {
        setUserPhotoUrl("")
        setIsLogin(false)
      }


    });


  }, []);


  const HeaderLeftCom = () => {
    if (isLogin) {
      if (pathname === "/profile") { return <Button onClick={handleLogOut} variant="contained">Log Out</Button> }
      else if (pathname === "/profile/") { return <Button onClick={handleLogOut} variant="contained">Log Out</Button> }
      else if (pathname === "/room/video-chat") { return null }
      else if (pathname === "/room/video-chat/") { return null }
      else if (pathname === "/room/text-chat") { return null }
      else if (pathname === "/room/text-chat/") { return null }
      else if (pathname === "/create-account") { return null }
      else if (pathname === "/create-account/") { return null }
      else if (pathname === "/create-account/login") { return null }
      else if (pathname === "/create-account/login/") { return null }
      else if (pathname === "/create-account/login/forgot-password") { return null }
      else if (pathname === "/create-account/login/forgot-password") { return null }

      else {
        return <Avatar component={Link} to={"/profile"} src={userPhotoUrl} />
      }

    } else {
      if (pathname === "/create-account") { return null }
      else if (pathname === "/create-account/") { return null }
      else if (pathname === "/create-account/login") { return null }
      else if (pathname === "/create-account/login/") { return null }
      else if (pathname === "/create-account/login/forgot-password") { return null }
      else if (pathname === "/create-account/login/forgot-password/") { return null }
      else {
        return <Link to={'/create-account'}><Button variant="contained">Sign In</Button></Link>

      }
    }
  }





  const handleLogOut = () => {
    setIsLoading(true)
    const auth = getAuth(app);
    signOut(auth).then(() => {

      setTimeout(() => {

        setIsLoading(false)
        setShowLogOutAlert(true)
        setTimeout(() => {
          setShowLogOutAlert(false)
          navigate("/create-account/login")
        }, 2000)


      }, 2000);

    }).catch((error) => {
      window.alert("Log out error : ", error)
    });
  }


  return (
    <div style={{ height: "100vh", width: "100%", overflow: "hidden" }}>

<Helmet>
                <meta charSet="utf-8" />
                <title>Rimiex</title>
                <meta name="description" content="Rimiex is an online video and text chat app similar to Omegle. Talk to random people all over the world and make new friends." />
                <meta name="keywords" content="Rimiex,rimiex video calling,rimiex com, rimiex .com, video-chat,audio chat, rimiex, rimiex random video call, rimiex random text chat, rimiex random chat, remiex,rimix,remex,remeex, random video calling app, random video call, video call, chat app, random chat app, random chat, chat, free random video call, free random video chat, video calling, online communication, video conferencing, online video call, online video chat, chatting, video chating,live chat, audio, audio chat app, xnxx, xvideos, xxx, pronhub, omegal,omegal video calling, whatsapp, monkey, monkey video calling, holla, holla video calling,Chatrandom, Chatrandom video calling,Chatspin, Chatspin video calling,hay, hay video calling, omega app,omega, omega video calling,quora, omeTv, ometv video calling, 18+ video calling app,tinychat,coomeet,google meet, google, facebook,live video call,x,Chatroulette, azar,chathub,top chat site, top video call app, video app, indian video calling app, meet strangers using your webcam, talk to strangers,free video calling app, free random video calling app, free app, free random, paid video calling app, free video calling website, new video calling website, new video calling app,website, audio,video,text, chat app, messenger,messenger video call,messenger chat" />
                <link rel="canonical" href="https://www.rimiex.com/" />
            </Helmet>

      <AppBar position="static" elevation={0} sx={{ elevation: 5, borderBottom: "1px solid black", backgroundColor: '#102030' }}>
        <Toolbar>
          {pathname !== "/room/video-chat" && pathname !== "/room/video-chat/" && pathname !== "/room/text-chat" && pathname !== "/room/text-chat/" ?
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <Menu />
            </IconButton>
            : null}


          <Box sx={{ width: "100%", height: "100%"}}>
            <Box sx={{marginTop:"8px", float: 'left', display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
              <img alt='app-logo' src='rimiex-logo-name-img.png' style={{ height:"25px",width:"100px" }} />
            </Box>

            <Box sx={{ float: 'right' }}>
              <HeaderLeftCom />
            </Box>
          </Box>

        </Toolbar>
      </AppBar>
      <Box sx={{ width: "100%", height: "calc(100% - 65px)", display: "flex", justifyContent: 'center', alignItems: "center", overflow: 'hidden' }}>

        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/room/video-chat' element={<VideoChatPage />} />
          <Route path='/room/text-chat' element={<TextChatPage />} />
          <Route path='/create-account' element={<CreateAccount />} >
            <Route path='' element={<SignIn />} />
            <Route path='login' element={<Login />} />
            <Route path='login/forgot-password' element={<ForgotPassword />} />
          </Route>
          <Route path='/profile' element={<Profile />} />
          <Route path='/help-and-support' element={<HelpAndSupport />} />

          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/billing' element={<Billing />} />
          <Route path='/bad-request-400' element={<div style={{ width: "100%", height: "100%", backgroundColor: "#354555", display: 'flex', justifyContent: 'center', alignItems: "center" }}><h1 style={{ color: "white" }}>400 Bad Request</h1></div>} />
          <Route path='*' element={<div style={{ width: "100%", height: "100%", backgroundColor: "#354555", display: 'flex', justifyContent: 'center', alignItems: "center" }}><h1 style={{ color: "white" }}>404 Not Found</h1></div>} />
        </Routes>

      </Box>


      <SwipeableDrawer
        open={openDrawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <Box sx={{ width: "250px", height: "100%", padding: "20px", backgroundColor: "#253545" }}>
          <List>
            {
              menuList.map((item) => (
                <ListItem key={item.id} disablePadding>
                  {
                    item.name === "Billing" ? isLogin ?
                      <ListItemButton onClick={toggleDrawer} component={Link} to={item.path}>
                        <ListItemIcon sx={{
                          minWidth: 0,
                          mr: 2,
                          justifyContent: 'center',
                          color: "white"
                        }}>
                          <span className="material-icons">{item.icon}</span>
                        </ListItemIcon>
                        <ListItemText sx={{ color: "white" }} primary={item.name} />
                      </ListItemButton>
                      : null
                      : <ListItemButton onClick={toggleDrawer} component={Link} to={item.path}>
                        <ListItemIcon sx={{
                          minWidth: 0,
                          mr: 2,
                          justifyContent: 'center',
                          color: "white"
                        }}>
                          <span className="material-icons">{item.icon}</span>
                        </ListItemIcon>
                        <ListItemText sx={{ color: "white" }} primary={item.name} />
                      </ListItemButton>
                  }


                </ListItem>
              ))
            }
            <Divider />
          </List>
        </Box>
      </SwipeableDrawer>
      {isLoading ? <MyLoader /> : null}
      {showLogOutAlert ? <MyAlert color={"#1c96e8"} icon={"check"} bigText={"Logout Success"} /> : null}


    </div>
  )
}

export default App