import React from 'react'
import {
    Box, Typography
} from '@mui/material'

const MyAlert = ({color,bigText,smallText,icon}) => {
  return (
    <div style={{ width:"100vw",height:"100vh",position:"absolute",top:"0",left:"0",backgroundColor:"rgba(0,0,0,0.7)",display:"flex",justifyContent:'center',alignItems:"center",zIndex:6}}>
    <Box sx={{width:"65%",borderRadius:"15px",height:"fit-content",fontSize:"20px",backgroundColor:color,padding:"20px",paddingTop:"25px",paddingBottom:"25px"}}>
    
    <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"10px"}}>
    <Box sx={{height:"fit-content",padding:"10px",backgroundColor:"white",display:"flex",justifyContent:'center',borderRadius:"50%"}}>
    <span className="material-icons" style={{fontSize:"40px",fontWeight:"bold",color:color}}>{icon}</span>
    </Box>
    </div>

    <Box sx={{width:"100%",height:"fit-content"}}>
<Typography sx={{textAlign:'center',fontSize:"25px",fontWeight:"bold",color:"white"}}>{bigText}</Typography>
    </Box>

{
  smallText? <Box sx={{width:"100%",height:"fit-content",marginTop:"5px"}}>
  <Typography sx={{textAlign:'center',fontSize:"17px",color:"white"}}>{smallText}</Typography>
      </Box> :null
}
   

    </Box>
    </div> 
  )
}

export default MyAlert