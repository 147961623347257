import React from 'react'
import Loader from "react-js-loader";

const MyLoader = () => {
  return (
    <div style={{height:"100vh", width:"100vw",position:'absolute',top:0,left:0,backgroundColor:"rgba(0,0,0,0.7)",display:"flex",justifyContent:"center",alignItems:"center",zIndex:5}}>
    <div style={{backgroundColor:"#1c96e8",padding:"30px",borderRadius:"15px"}}>
       <Loader type="spinner-default" bgColor={"rgb(255,255,255)"} color={"rgb(255,255,255)"} size={70} />
    </div>
    </div>
  ) 
}

export default MyLoader