import { Box,Typography } from '@mui/material'
import React from 'react'


const TextAlert = ({color,icon,text}) => {
  return (
    <div style={{ width:"100vw",height:"100vh",position:"absolute",top:"0",left:"0",backgroundColor:"rgba(0,0,0,0.7)",display:"flex",justifyContent:'center',alignItems:"center",zIndex:6}}>
        <Box sx={{width:"65%",borderRadius:"15px",height:"fit-content",fontSize:"20px",backgroundColor:color,padding:"20px",paddingTop:"25px",paddingBottom:"25px"}}>
    <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"10px"}}>
    <Box sx={{height:"fit-content",padding:"10px",backgroundColor:"white",display:"flex",justifyContent:'center',borderRadius:"50%"}}>
    <span className="material-icons" style={{fontSize:"40px",fontWeight:"bold",color:color}}>{icon}</span>
    </Box>
    </div>
    
    <Typography sx={{textAlign:'center',fontSize:"20px",color:"white"}}>{text}</Typography>
   </Box>
    </div>
  )
}

export default TextAlert