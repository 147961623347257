import React, { useState, useEffect } from 'react'
import PricingCard from '../Other/PricingCard'
import { Box, Grid } from '@mui/material'
import MyAlert from '../Other/MyAlert'
import { useNavigate } from 'react-router-dom'
import MyLoader from '../Other/MyLoader'
import { app } from '../../firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, getFirestore, updateDoc, } from 'firebase/firestore';

const auth = getAuth(app)
const dbs = getFirestore(app)

const Pricing = () => {
  const navigate = useNavigate();
  const [gridItemXS, setGridItemXS] = useState(3)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showFailedAlert, setShowFailedAlert] = useState(false)
  const [showPlanActiveAlert, setshowPlanActiveAlert] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [showLoaderTwo, setShowLoaderTwo] = useState(true)
  const [activePlanName,setActivePlanName]=useState('Free')

  const handleSuccessAlert = () => {
    setShowSuccessAlert(true)
    setTimeout(() => {
      setShowSuccessAlert(false)
      navigate("/profile")
    }, 3000)
  }

  const handleFailedAlert = () => {
    setShowFailedAlert(true)
    setTimeout(() => {
      setShowFailedAlert(false)

    }, 3000)
  }

  const handleActivePlanAlert = () => {
    setshowPlanActiveAlert(true)
    setTimeout(() => {
      setshowPlanActiveAlert(false)

    }, 5000)
  }


  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    if (screenWidth < 1300) {
      setGridItemXS(4)
    } if (screenWidth < 900) {
      setGridItemXS(6)
    } if (screenWidth < 650) {
      setGridItemXS(12)
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);




  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setTimeout(() => {
          checkUserCurrentPlan(user.uid)
        }, 2000)
      } else {

        navigate("/create-account")

      }

      // Unsubscribe after the initial authentication state check
      unsubscribe();
    }, [auth]);

    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [navigate]);



  const checkUserCurrentPlan = async (currentUserId) => {
    const currentDate = new Date()
    try {

      const querySnapshot = await getDocs(collection(dbs, "UserBilling", currentUserId, "activePlan"));
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const expire_date = doc.data().expireDate.toDate()
          if (doc.data().status === "active") {

            if (currentDate > expire_date) {
              await updateDoc(doc.ref, { status: 'expired' }).then(() => {
                setShowLoaderTwo(false)
              });
            }else{

              //get your data
              setActivePlanName(doc.data().planName)
              setShowLoaderTwo(false)
              
            }
          } else {
            setShowLoaderTwo(false)
          }


        });
      } else {
        setShowLoaderTwo(false)
      }

    } catch (error) {
      console.log("error from checking current plan: " + error)
    }
  }

  return (
    <Box sx={{
      height: "100%", width: "100%", overflowY: "auto", backgroundColor: "#253545",
      "@media screen and (max-width: 1000px)": {
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "25px",


      },
    }}>

      <Grid container sx={{ width: "100%", height: "100%" }}>
        <Grid xs={gridItemXS} item sx={{ display: "flex", justifyContent: "center", marginTop: "35px", marginBottom: "35px" }} >
          <PricingCard
            planName={"Free"}
            time={"For your need"}
            discount={"30%"}
            price={0}
            text1={"Ads free"}
            text2={"No time limits"}
            text3={"Unlimited Video chats"}
            text4={"Unlimited Text chats"}
            color={"white"}
            activePlanName={activePlanName==="Free"?"Active":null}
             />
        </Grid>

        <Grid xs={gridItemXS} item sx={{ display: "flex", justifyContent: "center", marginTop: "35px", marginBottom: "35px" }}>
          <PricingCard
            planName={"Silver"}
            time={"For 1 month"}
            discount={"0%"}
            realPrice={"$10"}
            price={10}
            text1={"Ads free"}
            text2={"No time limits"}
            text3={"Unlimited Video chats"}
            text4={"Unlimited Text chats"}
            color={"#bcc6cc"}
            successFun={handleSuccessAlert}
            warningFun={handleActivePlanAlert}
            failedFun={handleFailedAlert}
            showLoader={setShowLoader}
            month={1} 
            activePlanName={activePlanName==="Silver"?"Active":null}
            />
        </Grid>

        <Grid xs={gridItemXS} item sx={{ display: "flex", justifyContent: "center", marginTop: "35px", marginBottom: "35px" }}>
          <PricingCard
            planName={"Gold"}
            time={"For 6 month"}
            discount={"40%"}
            realPrice={"$60"}
            price={36}
            text1={"Ads free"}
            text2={"No time limits"}
            text3={"Unlimited Video chats"}
            text4={"Unlimited Text chats"}
            color={"#FFD700"}
            successFun={handleSuccessAlert}
            warningFun={handleActivePlanAlert}
            failedFun={handleFailedAlert}
            showLoader={setShowLoader}
            month={6} 
            activePlanName={activePlanName==="Gold"?"Active":null}
            />
        </Grid>

        <Grid xs={gridItemXS} item sx={{ display: "flex", justifyContent: "center", marginTop: "35px", marginBottom: "35px" }}>
          <PricingCard
            planName={"Diamond"}
            time={"For 12 month"}
            discount={"20%"}
            realPrice={"$120"}
            price={96}
            text1={"Ads free"}
            text2={"No time limits"}
            text3={"Unlimited Video chats"}
            text4={"Unlimited Text chats"}
            color={"#4ee2ec"}
            successFun={handleSuccessAlert}
            warningFun={handleActivePlanAlert}
            failedFun={handleFailedAlert}
            showLoader={setShowLoader}
            month={12} 
            activePlanName={activePlanName==="Diamond"?"Active":null}
            />



        </Grid>

      </Grid>

      {
        showSuccessAlert ? <MyAlert color={"green"} icon={"check"} bigText={"Payment Success"} /> : null
      }

      {
        showFailedAlert ? <MyAlert color={"red"} icon={"close"} bigText={"Payment Failed"} /> : null
      }


      {
        showPlanActiveAlert ? <MyAlert color={"#c99d3e"} icon={"report_gmailerrorred"} bigText={"Already Have Active Plan"} smallText={"You already have an active plan. You can purchase a new plan after your active plan ends."} /> : null
      }

      {
        showLoader ? <MyLoader /> : null
      }

      {
        showLoaderTwo ? <MyLoader /> : null
      }
    </Box>
  )
}

export default Pricing