import React, { useEffect, useState } from 'react'
import {
    Box,
    TextField,
    Grid,
    Typography,
    Button
} from '@mui/material'
import { styled } from '@mui/system';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { getAuth } from "firebase/auth";
import { app } from '../../firebase'
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import MyLoader from '../Other/MyLoader';
import TextAlert from '../Other/TextAlert';




const auth = getAuth(app);
const dbs = getFirestore(app)

const HelpAndSupport = () => {

    const [showLoader, setShowLoader] = useState(false)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [messageError, setMessageError] = useState(false)

    const [nameErrorText, setNameErrorText] = useState("")
    const [emailErrorText, setEmailErrorText] = useState("")
    const [messageErrorText, setMessageErrorText] = useState("")

    const getWordCount = () => {
        const words = message.split(/\s+/);
        return words.length;
    };

    const handelSubmitButtonClick = () => {

        const messageWordCount = getWordCount();

        if (name !== "" && email !== "" && message !== "") {
            if (name.trim().length >= 3 && email.trim().length >= 8 && messageWordCount >= 25) {
                if (!email.includes(' ')) {
                    const chackCapPattern = /[A-Z]/;
                    if (!chackCapPattern.test(email)) {
                        const allowedPattern = /^[a-z0-9.@]+$/;
                        if (allowedPattern.test(email)) {
                            if (email.endsWith("@gmail.com") || email.endsWith("@yahoo.com") || email.endsWith("@hotmail.com") || email.endsWith("@live.com") || email.endsWith("@icloud.com") || email.endsWith("@outlook.com") || email.endsWith("@zoha.com")) {
                                setShowLoader(true)
                                storeData()
                            }
                        }
                    }
                }
            }

        }


        // Start Full Name Validition Coding

        if (name === "") {
            setNameError(true)
            setNameErrorText("This field is empty")
        } else {
            setNameError(false)
            setNameErrorText("")
            if (name.trim().length >= 3) {
                setNameError(false)
                setNameErrorText("")
            } else {
                setNameError(true)
                setNameErrorText("Enter at least 3 character")
            }
        }

        // Start Email Validition Coding

        if (email === "") {
            setEmailError(true)
            setEmailErrorText("This field is empty")
        } else {
            setEmailError(false)
            setEmailErrorText("")
            if (email.includes(' ')) {
                setEmailError(true)
                setEmailErrorText("Spaces are not allowed")
            } else {
                const chackCapPattern = /[A-Z]/;
                if (chackCapPattern.test(email)) {
                    setEmailError(true)
                    setEmailErrorText("Capital letters are not allowed")
                } else {

                    const allowedPattern = /^[a-z0-9.@]+$/;
                    if (allowedPattern.test(email)) {
                        if (email.trim().length >= 8) {
                            setEmailError(false)
                            setEmailErrorText("")
                            if (email.endsWith("@gmail.com") || email.endsWith("@yahoo.com") || email.endsWith("@hotmail.com") || email.endsWith("@live.com") || email.endsWith("@icloud.com") || email.endsWith("@outlook.com") || email.endsWith("@zoha.com")) {
                                setEmailError(false)
                                setEmailErrorText("")
                            } else {
                                setEmailError(true)
                                setEmailErrorText("Email id is not valid")
                                setEmailErrorText("Email id is not valid email can be ending with : ( @gmail.com, @yahoo.com, @hotmail.com, @live.com, @icloud.com and @outlook.com )")
                            }
                        } else {
                            setEmailError(true)
                            setEmailErrorText("Email id is not valid")
                        }
                    } else {

                        setEmailError(true)
                        setEmailErrorText("Only small letters, numbers, . (dot), and @ are allowed")
                    }
                }


            }


        }

        // Start Message Validition Coding

        if (message === "") {
            setMessageError(true)
            setMessageErrorText("This field is empty")
        } else {
            setMessageError(false)
            setMessageErrorText("")
            if (messageWordCount >= 25) {
                setMessageError(false)
                setMessageErrorText("")
            } else {
                setMessageError(true)
                setMessageErrorText("Enter at least 25 words")
            }
        }

    }

    const storeData = async () => {
        const date = new Date()
        try {
            await addDoc(collection(dbs, "HelpAndSupport"), {
                name: name,
                email: email,
                message: message,
                date: date,
                status:"pending"
            }).then(() => {

                showSuccessAlertFun()

            })
        } catch (error) {
            setShowLoader(false)
            window.alert("Error from storeing data : " + error)
        }
    }


    const showSuccessAlertFun = () => {

        setTimeout(() => {
            setName('')
            setEmail('')
            setMessage('')
            setShowLoader(false)
            setShowSuccessAlert(true)
            setTimeout(() => {
                setShowSuccessAlert(false)
            }, 3000)
        }, 2000)
    }

    return (
        <Box sx={{ width: "100%", height: "100%", overflowY: "auto", backgroundColor: "#253545" }}>

            <Typography sx={{ width: "100%", textAlign: "center", marginTop: "25px", fontWeight: "bold", fontSize: "30px", color: "gold" }}>Need some help?</Typography>
            <Box sx={{ width: "calc(100% - 50px)", margin: "25px", height: 'auto', display: 'flex', justifyContent: 'center' }}>

                <Box sx={{
                    width: "700px",
                    height: "auto",
                    backgroundColor: "#354555",
                    margin: "25px",
                    marginTop: "0px",
                    borderRadius: "15px",
                    boxShadow: 10,
                    padding: "30px",
                    boxSizing: "border-box",
                    position: 'relative'

                }}>

                    <Typography sx={{ width: "100%", height: 'auto', textAlign: 'center', fontSize: "25px", color: 'white', paddingBottom: "25px" }}>Contact Us</Typography>

                    <Box sx={{ width: "100%", height: 'auto', padding: "25px", boxSizing: 'border-box', border: "1px solid #ddd", marginBottom: "35px", borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                        <MailOutlineIcon sx={{ float: "left", marginRight: "10px", fontSize: "25px", color: "white" }} /> <Typography sx={{ float: "left", fontSize: "18px", fontWeight: 700, color: "white" }}>support@rimiex.com</Typography>

                    </Box>

                    <Box sx={{
                        width: "100%",
                        height: "50px",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',


                    }}>
                        <hr style={{ width: "calc(50% - 30px)", height: "3px", backgroundColor: "skyblue", border: "none" }} />
                        <Box sx={{ width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "skyblue", borderRadius: "30px" }}>
                            <Typography sx={{ fontSize: "25px", fontWeight: 'bold', color: 'white' }}>Or</Typography>
                        </Box>
                        <hr style={{ width: "calc(50% - 30px)", height: "3px", backgroundColor: "skyblue", border: "none" }} />
                    </Box>
                    <Typography sx={{ width: "100%", height: 'auto', textAlign: 'center', fontSize: "25px", color: 'white', paddingBottom: "25px", marginTop: "20px" }}>Help & Support</Typography>

                    <CustomTextField
                        fullWidth
                        label="Full Name"
                        placeholder='Enter your full name here'
                        sx={{ marginBottom: "25px" }}
                        error={nameError}
                        helperText={nameErrorText}
                        value={name}
                        onInput={(text) => {
                            setName(text.target.value)
                            setNameError(false)
                            setNameErrorText("")
                        }}
                    />

                    <CustomTextField
                        fullWidth
                        label="Email Id"
                        placeholder='Enter your email id here'
                        sx={{ marginBottom: "25px" }}
                        error={emailError}
                        helperText={emailErrorText}
                        value={email}
                        onInput={(text) => {
                            setEmail(text.target.value)
                            setEmailError(false)
                            setEmailErrorText("")
                        }}
                    />

                    <CustomTextField
                        fullWidth
                        label="Message"
                        placeholder='Enter your query here'
                        multiline
                        rows={5}
                        sx={{ marginBottom: "25px", color: "white" }}
                        error={messageError}
                        helperText={messageErrorText}
                        value={message}
                        onInput={(text) => {
                            setMessage(text.target.value)
                            setMessageError(false)
                            setMessageErrorText("")
                        }}
                    />

                    <Button onClick={handelSubmitButtonClick} variant='contained' fullWidth sx={{ fontSize: "17px" }}>Submit</Button>



                </Box>



            </Box>
            {
                showLoader ? <MyLoader /> : null
            }

            {
                showSuccessAlert ? <TextAlert color={"green"} icon={"check"} text={"Submite success"} /> : null
            }
        </Box>
    )
}

export default HelpAndSupport


const CustomTextField = styled(TextField)({
    '& input': {
        color: 'white',
        fontSize: "17px"
    },

    '& label': {
        color: '#ddd',
        fontSize: "17px"
    },
    '& label.Mui-focused': {
        color: '#1976D2', // Label color when focused
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ddd',
        },
        '&:hover fieldset': {
            borderColor: '#1976D2',
        },
        '& .MuiInputLabel-root': {
            color: "#ddd",
        },
        '& .MuiInputBase-input::placeholder': {
            color: "#ddd",
        },
        '& textarea': {
            color: 'white',
        },

    }
});


/*

<Box sx={{
    width:"calc(40%)",
    height:"50px",
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    marginTop:'25px',
    marginLeft:"30%"

    }}>
        <hr style={{width:"calc(50% - 30px)",height:"2px",backgroundColor:"white"}}/>
<Typography sx={{fontSize:"25px",fontWeight:'bold',color:"white",width:"60px",textAlign:'center'}}>Or</Typography>
<hr style={{width:"calc(50% - 30px)",height:"2px",backgroundColor:"white"}}/>
</Box>
*/