import { Box, Button, TextField, Typography, InputAdornment, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { getAuth, GoogleAuthProvider, signInWithPopup,createUserWithEmailAndPassword,updateProfile } from "firebase/auth";
import { app } from '../../firebase'
import { collection, getDocs, setDoc, doc, getFirestore, where, query } from 'firebase/firestore';
import { Google, VisibilityOff, Visibility } from '@mui/icons-material'
import { styled } from '@mui/system';
import MyLoader from '../Other/MyLoader';
import MyAlert from '../Other/MyAlert';
import { useNavigate, Link } from 'react-router-dom'






const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const dbs = getFirestore(app)


const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showSignInAlert, setShowSingINAlert] = useState(false)
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [fullName, setFullName] = useState("")
  const [emailId, setEmailId] = useState("")
  const [password, setPassword] = useState("")

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const [nameErrorText, setNameErrorText] = useState("")
  const [emailErrorText, setEmailErrorText] = useState("")
  const [passwordErrorText, setPasswordErrorText] = useState("")




  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handelSubmitButtonClick = () => {
    if (fullName !== "" && emailId !== "" && password !== "") {
      if (fullName.trim().length >= 3 && emailId.trim().length >= 8 && password.trim().length >= 6) {
        if (!emailId.includes(' ') && !password.includes(' ')) {
          const chackCapPattern = /[A-Z]/;
          if(!chackCapPattern.test(emailId)){
          const allowedPattern = /^[a-z0-9.@]+$/;
          if (allowedPattern.test(emailId)) {
            if (emailId.endsWith("@gmail.com") || emailId.endsWith("@yahoo.com") || emailId.endsWith("@hotmail.com") || emailId.endsWith("@live.com") || emailId.endsWith("@icloud.com") || emailId.endsWith("@outlook.com")  || emailId.endsWith("@zoha.com") ) {
              handelEmailPasswordSignUp()
            }
          }
        }
        }
      }

    }


// Start Full Name Validition Coding

    if (fullName === "") {
      setNameError(true)
      setNameErrorText("This field is empty")
    } else {
      setNameError(false)
      setNameErrorText("")
      if (fullName.trim().length >= 3) {
        setNameError(false)
        setNameErrorText("")
      } else {
        setNameError(true)
        setNameErrorText("Enter at least 3 character")
      }
    }

// Start Email Validition Coding

    if (emailId === "") {
      setEmailError(true)
      setEmailErrorText("This field is empty")
    } else {
      setEmailError(false)
      setEmailErrorText("")
      if (emailId.includes(' ')) {
        setEmailError(true)
        setEmailErrorText("Spaces are not allowed")
      } else {
        const chackCapPattern = /[A-Z]/;
        if(chackCapPattern.test(emailId)){
          setEmailError(true)
          setEmailErrorText("Capital letters are not allowed")
        }else{
         
          const allowedPattern = /^[a-z0-9.@]+$/;
        if(allowedPattern.test(emailId)){
          if (emailId.trim().length >= 8) {
            setEmailError(false)
            setEmailErrorText("")
            if (emailId.endsWith("@gmail.com")  || emailId.endsWith("@yahoo.com")  || emailId.endsWith("@hotmail.com")  || emailId.endsWith("@live.com")  || emailId.endsWith("@icloud.com")  || emailId.endsWith("@outlook.com")  || emailId.endsWith("@zoha.com") ) {
              setEmailError(false)
              setEmailErrorText("")
            } else {
              setEmailError(true)
              setEmailErrorText("Email id is not valid")
              setEmailErrorText("Email id is not valid email can be ending with : ( @gmail.com, @yahoo.com, @hotmail.com, @live.com, @icloud.com and @outlook.com )")
            }
          } else {
            setEmailError(true)
            setEmailErrorText("Email id is not valid")
          }
        }else{

      setEmailError(true)
      setEmailErrorText("Only small letters, numbers, . (dot), and @ are allowed")
      }
        }

  
      }


    }

// Start Password Validition Coding

    if (password === "") {
      setPasswordError(true)
      setPasswordErrorText("This field is empty")
    } else {
      if (password.includes(' ')) {
        setPasswordError(true)
        setPasswordErrorText("Spaces are not allowed")
      } else {
        setPasswordError(false)
        setPasswordErrorText("")
        if (password.trim().length >= 6) {
          setPasswordError(false)
          setPasswordErrorText("")
        } else {
          setPasswordError(true)
          setPasswordErrorText("Password must be 6 characters or longer")
        }
      }
    }


  }


  const handleGoogleSingIn = (provider) => {
    setIsLoading(true)
    signInWithPopup(auth, provider)
      .then((result) => {

        storeData();

      }).catch((error) => {
        window.alert(error)
        setIsLoading(false)

      });
  }


  const storeData = async () => {

    const querys = query(collection(dbs, "Users"), where("userId", "==", auth.currentUser.uid));
    const querySnapshot = await getDocs(querys);
    if (!querySnapshot.empty) {
      setIsLoading(false)
      setShowSingINAlert(true)
      setTimeout(() => {
        setShowSingINAlert(false)
        navigate('/profile')
      }, 3000)

    } else {


      await setDoc(doc(dbs, "Users", auth.currentUser.uid), {
        userName: auth.currentUser.displayName,
        userId: auth.currentUser.uid,
        userEmail: auth.currentUser.email,
        userPhotoUrl: auth.currentUser.email,
        status:'active'

      }).then(() => {
        setIsLoading(false)
        setShowSingINAlert(true)
        setTimeout(() => {
          setShowSingINAlert(false)
          navigate('/profile')
        }, 3000)
      });

    }
  }


  const handelEmailPasswordSignUp=async()=>{
    setIsLoading(true)

    createUserWithEmailAndPassword(auth, emailId, password)
    .then(async(userCredential) => {
      // Signed up 
      const user = userCredential.user;
      await updateProfile(user, {
        displayName: fullName,
        // other properties you want to update, like photoURL
      }).then(()=>{
        storeInputData(user.uid, user.email,user.photoURL)
      });
     

      // ...
    })
    .catch((error) => {
      setIsLoading(false)
    //  const errorCode = error.code;
      const errorMessage = error.message;
      if(errorMessage ==="Firebase: Error (auth/email-already-in-use)."){
        setEmailError(true);
        setEmailErrorText("The email id you provided is used in another account. Please try another email id.")
      }else{
        window.alert(error)
      }
     
    
      // ..
    });

  }

const storeInputData=async(uid,email,photourl)=>{

    await setDoc(doc(dbs, "Users", uid), {
      userName: fullName,
      userId: uid,
      userEmail: email,
      userPhotoUrl: photourl,
      status:"active"

    }).then(() => {

      setIsLoading(false)
      setShowSingINAlert(true)
      setTimeout(() => {
        setShowSingINAlert(false)
        navigate('/profile')
      }, 3000)
    });

  
}

  return (
    <Box sx={{ width: "100%", height: "auto" }}>

      <Typography style={{ fontSize: "25px", width: "100%", textAlign: "center", color: "white", marginBottom: "25px" }}>Sign Up</Typography>
      <CustomTextField
        fullWidth
        error={nameError}
        label="Full name"
        
        helperText={nameErrorText}
        value={fullName}
        onInput={(text) => {
          setFullName(text.target.value);
          setNameError(false)
          setNameErrorText("")
        }}
        sx={{ color: "white", marginBottom: "20px" }}
      />

      <CustomTextField
        fullWidth
        error={emailError}
        label="Email id"
        
        helperText={emailErrorText}
        value={emailId}
        onInput={(text) => {
          setEmailId(text.target.value);
          setEmailError(false)
          setEmailErrorText("")
        }}
        sx={{ color: "white", marginBottom: "20px" }}
      />

      <CustomTextField
        fullWidth
        error={passwordError}
        label="Password"
        
        helperText={passwordErrorText}
        value={password}
        onInput={(text) => {
          setPassword(text.target.value);
          setPasswordError(false)
          setPasswordErrorText("")
        }}
        type={showPassword ? 'text' : 'password'}
        sx={{ color: "white", marginBottom: "20px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff sx={{ color: "white" }} /> : <Visibility sx={{ color: "white" }} />}
              </IconButton>

            </InputAdornment>
          ),
        }}
      />

      <b style={{ fontSize: "16px", marginBottom: "20px", color: "white", fontWeight: "normal" }}>Already have an account <Link to={"/create-account/login"} style={{ color: "#1976D2", marginLeft: "3px", fontWeight: "bold" }}> Login</Link></b>

      <Button variant='contained' sx={{ width: "100%", height: "45px", fontSize: "16px", marginBottom: "20px", marginTop: "20px" }} onClick={handelSubmitButtonClick}>Submit</Button>

      <Typography sx={{ width: "100%", textAlign: 'center', color: "white", marginBottom: "25px" }}>OR</Typography>

      <Button variant='contained' color='error' sx={{ 
        width: "100%",
         height: "45px",
          fontSize: "16px",
           marginBottom: "50px",
           "@media screen and (max-width: 400px)": {
            fontSize:"15px",
          },
          "@media screen and (max-width: 369px)": {
            height:"auto"
  
          },
           }}
        onClick={() => { handleGoogleSingIn(googleProvider) }}
      ><Google sx={{ 
        marginRight: "10px",
        "@media screen and (max-width: 390px)": {
          fontSize:"20px",
        },
        "@media screen and (max-width: 377px)": {
          marginRight:"5px"

        },
        "@media screen and (max-width: 369px)": {
         fontSize:"25px"

        },
        
         }} /> Sign in with Google</Button>

      <Typography sx={{ width: "100%", textAlign: 'center', color: "white", fontSize: "16px" }}>By continuing you are agree to our <Link to={"/terms-and-conditions"} style={{color:"skyblue"}}>Terms of Conditions</Link> and <Link to={'/privacy-policy'} style={{color:"skyblue"}}>Privacy Policy</Link> make sure you are 18+ years old.</Typography>

      {showSignInAlert ? <MyAlert color={"green"} icon={"check"} bigText={"Sign In Success"}/> : null}
      {isLoading ? <MyLoader /> : null}

      

      

    </Box>

  )
}

export default SignIn


const CustomTextField = styled(TextField)({
  '& input': {
    color: 'white',
    fontSize: "17px"
  },

  '& label': {
    color: '#ddd',
    fontSize: "17px"
  },
  '& label.Mui-focused': {
    color: '#1976D2', // Label color when focused
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ddd',
    },
    '&:hover fieldset': {
      borderColor: '#1976D2',
    },

  }
});

