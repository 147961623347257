import { Box, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { app } from '../../firebase'
import {getAuth,onAuthStateChanged} from "firebase/auth";
import { collection, getDocs, getFirestore, } from 'firebase/firestore';
import MyLoader from '../Other/MyLoader';


const dbs = getFirestore(app)
const auth =getAuth(app)

const Billing = () => {
  const navigate = useNavigate();


  const [billData, setBillData] = useState([])
  const [showLoader, setShowLoader] = useState(true)




  const getUserBillData = async (userId) => {

    try {
      const querySnapshot = await getDocs(collection(dbs, "UserBilling", userId, "bills"));
      let data = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          // Push data into the array
          data.push(doc.data());
        });
      }

      setBillData(data);
      
    } catch (error) {
      console.log("Error fetching user bill data", error);
      
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user){
        getUserBillData(user.uid).then(()=>{
          setTimeout(()=>{
            setShowLoader(false)
          },2000)
        })
      }else{
   
          navigate("/bad-request-400")
        
    
      }
  
      // Unsubscribe after the initial authentication state check
      unsubscribe();
    }, [auth]);
  
    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [navigate]);


  return (
    <Box sx={{ width: "100%", height: "100%", backgroundColor: "#253545", overflowY: "auto" }}>
      {
        billData.length !== 0 ?
          billData.map((item, index) => (
            <Bill key={index} data={item} />
          ))
          : <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: 'center', alignItems: "center" }}><h2 style={{ color: "white" }}>Bill Not Found</h2></Box>

      }


      {
        showLoader ? <MyLoader /> : null
      }
    </Box>
  )
}

export default Billing


const Bill = (props) => {
  const { data } = props;

  const order_date = data.orderDate.toDate().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
  const expire_date = data.expireDate.toDate().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })

  return (

    <Box sx={{
      width: "calc(100% - 52px)",
      backgroundColor: "#354555",
      height: "auto",
      margin: "25px",
      boxShadow: 5,
      borderRadius: "15px",
      overflow: "hidden",

    }}>
      <Box sx={{
        float: "left",
        width: "140px",
        height: "130px",
        borderRight:"1px solid #ccc",
        "@media screen and (max-width: 1050px)": {
          width: "40%",
          borderBottom:"1px solid #ddd"
        },
        "@media screen and (max-width: 650px)": {
          width: "calc(100% - 2px)",
          borderRight:"none"
        }

      }}>
        <Typography sx={{
          fontSize: "25px",
          color: data.planName === "Diamond" ? "#4ee2ec" : data.planName === "Gold" ? "gold" : data.planName === "Silver" ? "#bcc6cc" : "white"
          , marginTop: "15px", marginBottom: "15px", textAlign: "center"
        }}>{data.planName}</Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Typography sx={{ fontSize: "17px", color: "white", marginBottom: "15px", padding: "10px", backgroundColor: data.status === "Paid" ? "green" : "red", width: "60px", textAlign: 'center', borderRadius: "5px" }}>{data.status}</Typography>
        </Box>
      </Box>
      <Box sx={{
        float: "left",
        width: "260px",
        height: "130px",
        borderRight: "1px solid #ddd",
        "@media screen and (max-width: 1050px)": {
          width: "calc(60% - 4px)",
          borderBottom:"1px solid #ddd",
          borderRight:"none"
        },
        "@media screen and (max-width: 650px)": {
          width: "calc(100% - 2px)"
        }

      }}>

        <Typography sx={{ fontSize: "18px", color: "white", marginTop: "11px", marginBottom: "12px", textAlign: 'center' }}>Validity : {data.status === "Paid" ? data.subTime : "Null"}</Typography>
        <Typography sx={{ fontSize: "18px", color: "deeppink", marginTop: "11px", marginBottom: "12px", textAlign: 'center' }}>Order date : {order_date}</Typography>
        <Typography sx={{ fontSize: "18px", color: "red", marginTop: "11px", marginBottom: "12px", textAlign: 'center' }}>Expiry date : {data.status === "Paid" ? expire_date : "Null"}</Typography>

      </Box>
      <Box sx={{
        float: "left",
        width: "calc(100% - 567px)",
        height: "130px",
        borderRight: "1px solid #ddd",
        "@media screen and (max-width: 1050px)": {
          width: "calc(100% - 164px)"
        },
        "@media screen and (max-width: 650px)": {
          width: "calc(100% - 2px)",
          borderRight:"none",
          borderBottom:"1px solid #ddd"
        },
        "@media screen and (max-width: 500px)": {
          height: "auto"
        },

      }}>

        <Typography sx={{ fontSize: "18px", color: "yellow", marginTop: "30px", marginBottom: "15px", textAlign: 'center' }}>Order id : {data.orderId}</Typography>
        <Typography sx={{ fontSize: "18px", color: "#02c6f6", marginTop: "15px", marginBottom: "15px", textAlign: 'center' }}>Payment id : {data.status==="Paid"?data.paymentId:"Null"}</Typography>
      </Box>
      <Box sx={{
        float: "left",
        width: "160px",
        height: "130px",
       
        "@media screen and (max-width: 650px)": {
          width: "calc(100% - 2px)"
        }

      }}>
        <Typography sx={{ fontSize: "18px", color: "white", marginTop: "11px", marginBottom: "12px", textAlign: 'center' }}>Real price : {data.realPrice}</Typography>
        <Typography sx={{ fontSize: "18px", color: "gold", marginTop: "11px", marginBottom: "12px", textAlign: 'center' }}>Discount : {data.discount}</Typography>
        <Typography sx={{ fontSize: "18px", color: "lightgreen", marginTop: "11px", marginBottom: "12px", textAlign: 'center', fontWeight: "bold" }}>Total : ${data.amount}</Typography>

      </Box>



    </Box>


  )
}