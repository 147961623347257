import React, { useState } from 'react'
import { styled } from '@mui/system';
import {getAuth,sendPasswordResetEmail} from "firebase/auth";
import { app } from '../../firebase'
import { Box, Typography, TextField, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import MyLoader from '../Other/MyLoader';



const auth =getAuth(app)

const ForgotPassword = () => {
  const [emailId, setEmailId] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState("")
  const[isLoading,setIsLoading]=useState(false)
  const [resetPasswordText,setResetPasswordText]=useState(false)
  const [isInputDisabled,setIsInputDisabled]=useState(false)


  const navigate = useNavigate();

  const handelSubmitButtonClick = () => {
    if (emailId === "") {
      setEmailError(true)
      setEmailErrorText("This field is empty")
    } else {
      setEmailError(false)
      setEmailErrorText("")
      if (emailId.includes(' ')) {
        setEmailError(true)
        setEmailErrorText("Spaces are not allowed")
      } else {
        const chackCapPattern = /[A-Z]/;
        if(chackCapPattern.test(emailId)){
          setEmailError(true)
          setEmailErrorText("Capital letters are not allowed")
        }else{
         
          const allowedPattern = /^[a-z0-9.@]+$/;
        if(allowedPattern.test(emailId)){
          if (emailId.trim().length >= 8) {
            setEmailError(false)
            setEmailErrorText("")
            if (emailId.endsWith("@gmail.com")  || emailId.endsWith("@yahoo.com")  || emailId.endsWith("@hotmail.com")  || emailId.endsWith("@live.com")  || emailId.endsWith("@icloud.com")  || emailId.endsWith("@outlook.com")  || emailId.endsWith("@zoha.com") ) {
              setEmailError(false)
              setEmailErrorText("")

              setIsLoading(true)
              setTimeout(()=>{
resetPassword()
              },1000)
            } else {
              setEmailError(true)
              setEmailErrorText("Email id is not valid")
              setEmailErrorText("Email id is not valid email can be ending with : ( @gmail.com, @yahoo.com, @hotmail.com, @live.com, @icloud.com and @outlook.com )")
            }
          } else {
            setEmailError(true)
            setEmailErrorText("Email id is not valid")
          }
        }else{

      setEmailError(true)
      setEmailErrorText("Only small letters, numbers, . (dot), and @ are allowed")
      }
        }

  
      }


    }


  }


const resetPassword=async()=>{
  sendPasswordResetEmail(auth,emailId).then((data)=>{

    setIsLoading(false)
setResetPasswordText(true)
setIsInputDisabled(true)

setTimeout(()=>{
  navigate('/create-account/login')
},15000)
  }).catch((error)=>{
    window.alert(error)
  })
}




  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Typography style={{ fontSize: "25px", width: "100%", textAlign: "center", color: "white", marginBottom: "25px" }}>Forgot Password</Typography>
      <CustomTextField
        fullWidth
        disabled={isInputDisabled}
        error={emailError}
        label="Email id"
        helperText={emailErrorText}
        value={emailId}
        onInput={(text) => {
          setEmailId(text.target.value);
          setEmailError(false)
          setEmailErrorText("")
        }}
        sx={{ color: "white", marginBottom: "20px" }}
      />


      <b style={{ fontSize: "16px", marginBottom: "20px", color: "white", fontWeight: "normal" }}>Remember password go to <Link to={"/create-account/login"} style={{ color: "#1976D2", marginLeft: "3px", fontWeight: "bold" }}> Login</Link></b>

      <Button variant='contained' sx={{ width: "100%", height: "45px", fontSize: "16px", marginTop: "20px"}} onClick={handelSubmitButtonClick}>Submit</Button>
{
  resetPasswordText?
<Typography sx={{fontSize:"20px",color:"lightgreen",textAlign:'center',marginTop:"20px",fontWeight:'bold'}}>We have send an email link to {emailId} please check your email and reset your password.</Typography>
  :null
}

     
      {isLoading ? <MyLoader /> : null}

    </Box>
  )
}

export default ForgotPassword

const CustomTextField = styled(TextField)({
  '& input': {
    color: 'white',
    fontSize: "17px"
  },

  '& label': {
    color: '#ddd',
    fontSize: "17px"
  },
  '& label.Mui-focused': {
    color: '#1976D2', // Label color when focused
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ddd',
    },
    '&:hover fieldset': {
      borderColor: '#1976D2',
    },

  }
});
