import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { app } from '../../firebase'
import { getAuth, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { collection, getDocs, getFirestore, updateDoc, } from 'firebase/firestore';
import { Box, Grid, Typography, Button } from '@mui/material'
import EmailVerifyAlert from '../Other/EmailVerifyAlert';
import { CardMembershipOutlined, EmailOutlined } from '@mui/icons-material'
import MyLoader from '../Other/MyLoader';
import TextAlert from '../Other/TextAlert';
import AlertForPlan from '../Other/AlertForPlan';



const dbs = getFirestore(app)
const auth = getAuth(app)

const Profile = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [userId, setUserId] = useState("")
  const [userPhotoUrl, setUserPhotoUrl] = useState("")
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showLoader, setShowLoader] = useState(true)
  const [billData, setBillData] = useState([])
  const [showEmailVerifyAlert, setshowEmailVerifyAlert] = useState(false)
  const [showPlanExpiredAlert,setshowPlanExpiredAlert]=useState(false)
  const [showPlanBuyAlert,setshowPlanBuyAlert]=useState(false)
  const [showTextAlert, setShowTextAlert] = useState(false)


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserName(user.displayName);
        setUserEmail(user.email);
        setUserId(user.uid);
        setUserPhotoUrl(user.photoURL);

        checkUserCurrentPlan(user.uid).then(() => {

         setTimeout(()=>{
          setShowLoader(false)
         },2000)

        });
      } else {

        navigate("/create-account/login");

      }

      // Unsubscribe after the initial authentication state check
      unsubscribe();
    }, [auth]);

    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [navigate]);




  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);




  const paragraphStyle = {
    float: 'left',
    marginLeft: '50px',
    marginTop: '15px',
    marginRight: '50px',
    fontSize: '25px',
    color: 'white',
  };

  // Check if the screen width is less than or equal to 930px
  if (window.innerWidth <= 990) {
    paragraphStyle.marginRight = '20px';
    paragraphStyle.marginLeft = '20px';
  }


  const checkUserCurrentPlan = async (currentUserId) => {
    const currentDate = new Date()
    try {
      const querySnapshot = await getDocs(collection(dbs, "UserBilling", currentUserId, "activePlan"));
      let data = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const expire_date = doc.data().expireDate.toDate()
          if (doc.data().status === "active") {

            if (currentDate > expire_date) {
              await updateDoc(doc.ref, { status: 'expired' }).then(() => console.log("update")).then(() => {
                window.location.reload();
              });
            }
          }else{
            if(doc.data().status==="expired"){
              setTimeout(() => {
                setShowLoader(false)
                setshowPlanExpiredAlert(true)
    
              }, 2000)
            }
          }
          data.push(doc.data());

        });
      }else{
        if (auth.currentUser.emailVerified === false) {
          setTimeout(() => {
            setShowLoader(false)
            setshowEmailVerifyAlert(true)

          }, 2000)

        } else {
          setTimeout(() => {
            setShowLoader(false)
            setshowPlanBuyAlert(true)
          }, 2000)
          setshowEmailVerifyAlert(false)
        }
      }

      setBillData(data);
    } catch (error) {
      console.log("Error fetching user bill data", error);
    }
  }


  const handelVerifyAlertVerifyBtnClick = async () => {
    setShowLoader(true)
    setshowEmailVerifyAlert(false)
    onAuthStateChanged(auth, async (user) => {
      await sendEmailVerification(user).then(() => {
        setTimeout(() => {
          setShowLoader(false)
          setShowTextAlert(true)
          setTimeout(() => {
            setShowTextAlert(false)
          }, 5000)
        }, 1000)

      })

    })


  }

const handelPlanAlertCancelButton=()=>{
  setshowPlanExpiredAlert(false)
  setshowPlanBuyAlert(false)
}

const handelPlanAlertUpgreadeButton=()=>{
  navigate("/pricing")
}

  return (
    <Box sx={{ height: "100%", width: "100%", backgroundColor: "#253545", overflowY: "auto", paddingLeft: "25px", paddingRight: "25px" }}>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "#354555", height: "430px", boxShadow: 5, marginTop: "25px", marginBottom: "25px", borderRadius: "20px", overflow: 'hidden', position: "relative" }}>

          <Box sx={{ width: "100%", height: "200px", backgroundColor: "#354555", overflow: 'hidden' }}>
            <img src='profile_background_img.jpg' alt='profile_background_img' style={{ height: "100%", width: "100%" }} />
          </Box>
          <Box sx={{
            width: "400px", height: "320px", borderRadius: "15px", position: 'absolute', top: "100px", left: 'clac(50% - 400px)'
          }}>
            <Box sx={{ width: "100%", height: "auto", paddingTop: "25px", paddingBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center", }}>
              <Box sx={{ width: "150px", height: "150px", borderRadius: "75px", border: "1px solid #ccc", overflow: "hidden", backgroundColor: "white" }}>
                <img src={userPhotoUrl?userPhotoUrl:"profile-user.png"} alt='Profile_image' style={{ width: "100%", height: "100%", }} />
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "auto", }}>
              <Typography variant='h5' sx={{ textAlign: 'center', mb: 2, color: "White" }}>{userName}</Typography>

              <Box sx={{ width: "100%", height: "auto", display: "flex", justifyContent: 'center', alignItems: "center", mb: 2 }}>
                <CardMembershipOutlined sx={{ mr: 1, color: "white" }} /><Typography sx={{ color: "white" }}>{userId}</Typography>
              </Box>
              <Box sx={{ width: "100%", height: "auto", display: "flex", justifyContent: 'center', alignItems: "center", mb: 3 }}>
                <EmailOutlined sx={{ mr: 1, color: "white" }} /><Typography sx={{ color: "white" }}>{userEmail}</Typography>
              </Box>

            </Box>

          </Box>
        </Grid>
        {
          billData.length !== 0 ?
            billData.map((item, index) => (
              <PlanBox key={index} data={item} />
            ))
            : <PlanBox data={[]} />
        }




      </Grid>
      {
        showLoader ? <MyLoader /> : null
      }

      {showEmailVerifyAlert ? <EmailVerifyAlert verifyFun={handelVerifyAlertVerifyBtnClick} cancelState={setshowEmailVerifyAlert} /> : null}
      {showPlanExpiredAlert ? <AlertForPlan upgradeFun={handelPlanAlertUpgreadeButton} cancelFun={handelPlanAlertCancelButton} bigText={"Plan expired"} smallText={"You plan has expired. Pleace upgrade your plan for unlimited benefits."}/> : null}
      {showPlanBuyAlert ? <AlertForPlan upgradeFun={handelPlanAlertUpgreadeButton} cancelFun={handelPlanAlertCancelButton} bigText={"Upgrade your plan"} smallText={"Your free plan will have some limits. So we recommend purchasing a new plan for your better experience."}/> : null}
      {
        showTextAlert ?
          <TextAlert color={'green'} icon={"check"} text={"We have send you an email verification link to your email "} />
          : null
      }
    </Box>
  )
}

export default Profile

const PlanBox = (props) => {
  const { data } = props;

  const order_date = data.length !== 0 ? data.orderDate.toDate().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null
  const expire_date = data.length !== 0 ? data.expireDate.toDate().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null

  return (
    <Grid item xs={12} sx={{
       height: "auto",
        backgroundColor: "#354555", 
        borderRadius: "15px",
         boxShadow: 5,
          overflow: "hidden",
          "@media screen and (max-width: 500px)": {
           marginBottom:"25px"
          },
           }}>
      <Box sx={{
        float: "left",
        width: "120px",
        height: "80px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: "1px solid #ddd",
        borderBottom: "1px solid #ddd",
        "@media screen and (max-width: 1025px)": {
          width: "calc(50% - 1px)",

        },
        "@media screen and (max-width: 500px)": {
          width: "100%",
          borderRight: "none",
          borderBottom: "none",
          height: "60px",
          marginTop:"10px"


        },
      }}>
        { data.planName === "Gold" || data.planName === "Silver" || data.planName === "Diamond" ?
        <Typography sx={{ width: "70px", backgroundColor: data.status === "active" ? 'green' : 'red', padding: "10px", borderRadius: "5px", textAlign: 'center', color: "white", fontSize: "18px" }}>{data.status}</Typography>
        :<Typography sx={{ width: "70px", backgroundColor: 'skyblue', padding: "10px", borderRadius: "5px", textAlign: 'center', color: "white", fontSize: "18px" }}>Null</Typography>
      }
        </Box>
      <Box sx={{
        float: "left",
        width: "150px",
        height: "80px",
        borderRight: "1px solid #ddd",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: "1px solid #ddd",
        "@media screen and (max-width: 1025px)": {
          width: "50%",
          borderRight: "none",

        },
        "@media screen and (max-width: 500px)": {
          width: "100%",
          borderRight: "none",
          borderBottom: "none",
          height: "50px",

        },
      }}>
        <Typography sx={{
          borderRadius: "5px", textAlign: 'center',
          color: data.planName === "Gold" ? "#FFD700" : data.planName === "Silver" ? "#bcc6cc" : data.planName === "Diamond" ? "#4ee2ec" : 'white',
          fontSize: "25px"
        }}>{

            data.planName !== "Gold" && data.planName !== "Silver" && data.planName !== "Diamond" ? "Free" : data.planName
          }</Typography>
      </Box>

      <Box sx={{
        float: "left",
        width: "calc(50% - 247px)",
        height: "80px",
        borderRight: "1px solid #ddd",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: "1px solid #ddd",
        "@media screen and (max-width: 1025px)": {
          width: "calc(50% - 111px)",
        },
        "@media screen and (max-width: 750px)": {
          width: "calc(50% - 1px)",

        },
        "@media screen and (max-width: 500px)": {
          width: "100%",
          borderRight: "none",
          borderBottom: "none",
          height: "50px",

        },
      }}>
        <Typography sx={{ borderRadius: "5px", textAlign: 'center', color: "white", fontSize: "18px" }}>Start date : {
          data.planName !== "Gold" && data.planName !== "Silver" && data.planName !== "Diamond" ? "Null" : order_date
        }</Typography>
      </Box>

      <Box sx={{
        float: "left",
        width: "calc(50% - 247px)",
        height: "80px",
        borderRight: "1px solid #ddd",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: "1px solid #ddd",
        "@media screen and (max-width: 1025px)": {
          width: "calc(50% - 111px)",
        },
        "@media screen and (max-width: 750px)": {
          width: "50%",
          borderRight: 'none',
        },
        "@media screen and (max-width: 500px)": {
          width: "100%",
          borderRight: "none",
          borderBottom: "none",
          height: "50px",

        },
      }}>
        <Typography sx={{ borderRadius: "5px", textAlign: 'center', color: "red", fontSize: "18px" }}>Expiry date : {
          data.planName !== "Gold" && data.planName !== "Silver" && data.planName !== "Diamond" ? "Null" : expire_date
        }</Typography>
      </Box>

      <Box sx={{
        float: "left",
        width: "220px",
        height: "80px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: "1px solid #ddd",
        "@media screen and (max-width: 750px)": {
          width: "100%",

        },
        "@media screen and (max-width: 500px)": {
          height: "50px"

        },
      }}>
        <Typography sx={{ borderRadius: "5px", textAlign: 'center', color: "white", fontSize: "18px" }}>Validity : {
          data.planName !== "Gold" && data.planName !== "Silver" && data.planName !== "Diamond" ? "Null" : data.subTime

        }</Typography>
      </Box>
      {
        data.planName !== "Gold" && data.planName !== "Silver" && data.planName !== "Diamond" ?
          <Box sx={{ float: "left", display: 'flex', justifyContent: 'center', alignItems: "center", width: "100%", height: "auto", marginTop: "15px", marginBottom: "15px" }}>
            <Link to={"/pricing"}><Button variant='contained' sx={{ width: "250px" }}>Upgrade your plan</Button></Link>
          </Box>
          : <div>
            {data.status==="expired"?
          <Box sx={{ float: "left", display: 'flex', justifyContent: 'center', alignItems: "center", width: "100%", height: "auto", marginTop: "15px", marginBottom: "15px" }}>
            <Link to={"/pricing"}><Button variant='contained' color='error' sx={{ width: "250px" }}>Upgrade you plan</Button></Link>
          </Box>
          :null
}

          <Box sx={{ float: "left", display: 'flex', justifyContent: 'center', alignItems: "center", width: "100%", height: "auto", marginTop: "15px", marginBottom: "15px" }}>
            <Link to={"/billing"}><Button variant='contained' sx={{ width: "250px" }}>Go to billing</Button></Link>
          </Box>
          </div>
      }


    </Grid>
  )
}