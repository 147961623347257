import React from 'react'
import {
    Box, Typography,Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

const AlertForPlan = ({upgradeFun,cancelFun,smallText,bigText}) => {
    const navigate = useNavigate();
  return (
    <div style={{ width:"100vw",height:"100vh",position:"absolute",top:"0",left:"0",backgroundColor:"rgba(0,0,0,0.7)",display:"flex",justifyContent:'center',alignItems:"center",zIndex:6}}>
  <Box sx={{width:'fit-content',height:"fit-content",backgroundColor:'red',padding:"20px",borderRadius:"15px",marginLeft:"40px",marginRight:"40px"}}>
  <Box sx={{width:"100%",height:"auto",display:'flex',justifyContent:'center'}}>
  <span className="material-icons" style={{fontSize:"40px",color:"white"}}>shopping_cart</span>
  </Box>
    <Typography sx={{fontSize:"25px",color:"white",textAlign:'center',fontWeight:'bold',marginTop:"10px"}}>{bigText}</Typography>
    <Typography sx={{fontSize:"17px",color:"white",textAlign:'center',marginTop:"10px"}}>{smallText}</Typography>
  <Box sx={{width:"100%",height:'auto',marginTop:"25px"}}>
  <Button variant='contained' color='warning' sx={{width:"calc(50% - 10px)",marginRight:"10px"}}
  onClick={()=>{
    cancelFun()
  }}
  >cancel</Button>
  <Button variant='contained' color='primary' sx={{width:"calc(50% - 10px)",marginLeft:"10px"}}
  onClick={()=>{
    upgradeFun()
  }}
  >upgrade</Button>
  </Box>
  </Box>
    </div> 
  )
}

export default AlertForPlan