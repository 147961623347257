import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player'
import lottieJson from './sign_in.json'
import { useNavigate, Outlet } from 'react-router-dom'
import { Box, } from '@mui/material'
import MyLoader from '../Other/MyLoader';
import { app } from '../../firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth(app)

const CreateAccount = () => {
  const [showLoader, setShowLoader] = useState(true)
  const navigate = useNavigate()


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {

        navigate("/profile")

      } else {
        setTimeout(() => {
          setShowLoader(false)
        }, 3000)

      }

      // Unsubscribe after the initial authentication state check
      unsubscribe();
    }, [auth]);

    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [navigate]);


  return (
    <Box sx={{
      width: "100%", height: "100%", overflow: "hidden", backgroundColor: "#253545", display: "flex", justifyContent: 'center'
    }}>
      <Box sx={{
        width: "60%", height: "100%", float: "left", display: "block",

        "@media screen and (max-width: 950px)": {
          display: "none",

        },
      }}>
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: "100%", height: "100%" }}
        />

      </Box>
      <Box sx={{
        width: "40%", height: "100%", float: "right", display: "flex", justifyContent: "center", alignItems: 'center',

        "@media screen and (max-width: 950px)": {
          width: "60%",
        },
        "@media screen and (max-width: 700px)": {
          width: "80%",
        },
        "@media screen and (max-width: 500px)": {
          width: "100%",
        },
        "@media screen and (max-height: 800px)": {
          overflowY: "auto",

        }
      }}>
        <Box sx={{ width: "70%", height: "auto", padding: "25px", borderRadius: "15px", boxShadow: 5, backgroundColor: "#354555", overflow: "hidden" }}>

          <Outlet />


        </Box>

      </Box>



      {showLoader ? <MyLoader /> : null}

    </Box>
  )
}

export default CreateAccount

