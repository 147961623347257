// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBuNmDMc87NjjKmy1OOsf8beSvclLwlGC8",
  authDomain: "rimiex-97051.firebaseapp.com",
  projectId: "rimiex-97051",
  storageBucket: "rimiex-97051.appspot.com",
  messagingSenderId: "1048151143940",
  appId: "1:1048151143940:web:57023f0fdc1d4f5514a751",
  measurementId: "G-M47L5QWTNR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);