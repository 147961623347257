import React from 'react'
import {
    Box, Button, Typography
} from '@mui/material'



const EmailVerifyAlert = ({cancelState,verifyFun}) => {

const cancelFun =()=>{
  cancelState(false)
}

  
  return (

    <div style={{ width:"100vw",height:"100vh",position:"absolute",top:"0",left:"0",backgroundColor:"rgba(0,0,0,0.7)",display:'flex',justifyContent:'center',alignItems:"center",zIndex:6}}>

    <Box sx={{width:"65%",borderRadius:"15px",height:"fit-content",fontSize:"20px",backgroundColor:"#1c96e8",padding:"20px",paddingTop:"25px",paddingBottom:"25px"}}>
    
    <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"10px"}}>
    <Box sx={{height:"fit-content",padding:"10px",backgroundColor:"white",display:"flex",justifyContent:'center',borderRadius:"50%"}}>
    <span className="material-icons" style={{fontSize:"40px",fontWeight:"bold",color:"#1c96e8"}}>gpp_maybe</span>
    </Box>
    </div>

    <Box sx={{width:"100%",height:"fit-content"}}>
<Typography sx={{textAlign:'center',fontSize:"25px",fontWeight:"bold",color:"white"}}>Verify Your Email</Typography>
    </Box>

 <Box sx={{width:"100%",height:"fit-content",marginTop:"5px"}}>
  <Typography sx={{textAlign:'center',fontSize:"17px",color:"white"}}>After verify button clicked we will send you an email varification link for email varication</Typography>
      </Box>

<Box sx={{width:"100%",height:"fit-content",marginTop:"30px"}}>
<Button variant='contained' color='warning' sx={{width:"calc(50% - 20px)",float:"left",marginRight:"20px"}} onClick={()=>{cancelFun()}}>Cancel</Button>
    <Button variant='contained' color='secondary' sx={{width:"calc(50% - 20px)",float:"left",marginLeft:"20px"}} onClick={()=>{verifyFun()}}>Verify</Button>
</Box>
   

    </Box>


    </div> 
  )
}

export default EmailVerifyAlert