import React,{useState} from 'react'
import {getAuth,onAuthStateChanged,sendEmailVerification} from "firebase/auth";

import { Button, Box } from '@mui/material'
import { CheckCircle, Cancel } from '@mui/icons-material'
import { app } from '../../firebase'
import { collection, setDoc, doc, addDoc, getDocs, getFirestore, } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'
import EmailVerifyAlert from './EmailVerifyAlert';
import TextAlert from './TextAlert';






const dbs = getFirestore(app)
const auth = getAuth(app);
const PricingCard = ({ planName, time, realPrice, discount, price, text1, text2, text3,text4, color, showLoader, successFun,failedFun,warningFun,month,activePlanName }) => {
  const navigate = useNavigate();
  const [showEmailVerifyAlert, setshowEmailVerifyAlert] = useState(false)
  const [showTextAlert,setShowTextAlert]=useState(false)


  const checkUserCurrentPlan = async (userId, userEmail,userDisplayName) => {
  
    try {

      const querySnapshot = await getDocs(collection(dbs, "UserBilling", userId, "activePlan"));
      if (!querySnapshot.empty) {

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.data().status === "active") {
            warningFun()

            showLoader(false)
          } else {
            handelOnPaymentButtonClick(userId,userEmail,userDisplayName)
          }


        });
      } else {
        handelOnPaymentButtonClick(userId,userEmail,userDisplayName)
      }


    } catch (error) {
      window.alert("error for check plan", error)

      showLoader(false)
    }



  }

  const storeBill = async (paymentId, status,userId,userEmail,userDisplayName) => {
    const today = new Date()
    const currentDate = new Date();
    const futureDate = new Date(currentDate.setMonth(currentDate.getMonth() + month));

    const parentDocRef = doc(dbs, "UserBilling", userId);
    const subcollectionRef = collection(parentDocRef, "bills");
    const subcollectionRefTwo = collection(parentDocRef, "activePlan");

    try {
      const fackDocRef = await addDoc(subcollectionRef, {
        status: "pandding"
      })
      const orderId = fackDocRef.id

      await setDoc(doc(subcollectionRef, orderId), {
        status: status,
        paymentId: paymentId,
        amount: price,
        subTime: time,
        planName: planName,
        orderId: orderId,
        orderDate: today,
        expireDate: futureDate,
        realPrice:realPrice,
        discount:discount
      });
      if (status === "Paid") {
        await setDoc(doc(subcollectionRefTwo, "currentPlan"), {
          status: "active",
          orderDate: today,
          expireDate: futureDate,
          planName: planName,
          subTime: time,

        })
      }

    
    } catch (error) {
      window.alert("Error adding document: ", error);
    }
  }

  const handelOnPaymentButtonClick = async (userId,userEmail,userDisplayName) => {

    const options = {
      key: 'rzp_test_zPvEupQgsZMQEh',
      key_secret: "PtLw5P9umSERkDGaueNDFVB9",
      amount: price * 100,
      currency: "USD",
      //order_id: newDocRef.id.toString(),
      description: 'This is test payment',
      // Other options as needed
      handler: function (response) {

        if (response.razorpay_payment_id) {
          storeBill(response.razorpay_payment_id, "Paid",userId,userEmail,userDisplayName).then(() => {
            showLoader(false)
            successFun()
          })
        } else if (response.error) {
          storeBill("null", "faild",userId,userEmail,userDisplayName)
          showLoader(false)
          failedFun()
        }


      },

      prefill: {
        name: userDisplayName,
        email: userEmail
      },
      theme: {
        color: "#253545"
      },
      modal: {
        ondismiss: function () {
          // Handle the case where the user closes the payment window without completing the payment
          showLoader(false)
          // Perform any necessary actions or navigate to another page
        },
      },
      onClose: function () {
        showLoader(false)
      },
    };
    try {
      const pay = new window.Razorpay(options)
      pay.open()


    } catch (error) {
     
      storeBill("null", "faild")
      showLoader(false)
      failedFun()
      
    }



  }

  const checkLogOut = () => {
 
onAuthStateChanged(auth,(user)=>{
  if(user){
    
    if(user.emailVerified===false){
      showLoader(false)
      setshowEmailVerifyAlert(true)
    }else{
      showLoader(true)
      checkUserCurrentPlan(user.uid, user.email, user.displayName)
    }
   
      
  }else{
    showLoader(true)
    navigate("/create-accout")

  }
})

  }

  const handelVerifyAlertVerifyBtnClick=async()=>{
    showLoader(true)
    setshowEmailVerifyAlert(false)
    onAuthStateChanged(auth,async(user)=>{
      await sendEmailVerification(user).then(()=>{
        setTimeout(()=>{
          showLoader(false)
          setShowTextAlert(true)
          setTimeout(()=>{
           setShowTextAlert(false)
          },5000)
        },1000)
        
      })
      
    })


  }


  return (
    <Box sx={{ width: "85%", height: "fit-content",  borderRadius: "15px", overflow: "hidden",boxShadow:10,backgroundColor:'#354555'}}>
      <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
        <p style={{ padding: 0, margin: 0, fontSize: "27px", color: color, marginTop: "20px", fontWeight: "bold" }}>{planName}</p>
        <p style={{ padding: 0, margin: 0, fontSize: "16px", color: "white", marginTop: "10px" }}>{time}</p>
        {planName !== "Free" ?
          <p style={{ margin: 0, fontSize: "16px", color: "white", marginTop: "20px", backgroundColor: "#f7ad02", padding: "10px", borderRadius: "5px",fontWeight:'bold' }}>{discount} off</p>
          : null}



        {planName !== "Free" ? planName !== "Silver" ?
          <del style={{ padding: 0, margin: 0, fontSize: "20px", color: "red", marginTop: "15px" }}>{realPrice}</del>
          : <p style={{ padding: 0, margin: 0, fontSize: "20px", color: "red", marginTop: "15px" }}>{realPrice}</p>
          : null}

        <p style={{ padding: 0, margin: 0, fontSize: "30px", color: "white", marginTop: "10px" }}>{"$" + price}</p>

        <Box sx={{ marginTop: "20px" }}>
          {planName !== "Free" ?
            <CheckCircle sx={{ float: 'left', color: "green" }} /> :
            <Cancel sx={{ float: 'left', color: "red" }} />
          }

          <p style={{ padding: 0, margin: 0, fontSize: "16px", color: "white", float: "left", marginLeft: "10px" }}>{text1}</p>
        </Box>

        <Box sx={{ marginTop: "5px" }}>
          {planName !== "Free" ?
            <CheckCircle sx={{ float: 'left', color: "green" }} /> :
            <Cancel sx={{ float: 'left', color: "red" }} />
          }

          <p style={{ padding: 0, margin: 0, fontSize: "16px", color: "white", float: "left", marginLeft: "10px" }}>{text2}</p>
        </Box>

       

        <Box sx={{ marginTop: "5px" }}>
          {planName !== "Free" ?
            <CheckCircle sx={{ float: 'left', color: "green" }} /> :
            <Cancel sx={{ float: 'left', color: "red" }} />
          }
          <p style={{ padding: 0, margin: 0, fontSize: "16px", color: "white", float: "left", marginLeft: "10px" }}>{text3}</p>
        </Box>

        <Box sx={{ marginTop: "5px" }}>
          {planName !== "Free" ?
            <CheckCircle sx={{ float: 'left', color: "green" }} /> :
            <Cancel sx={{ float: 'left', color: "red" }} />
          }
          <p style={{ padding: 0, margin: 0, fontSize: "16px", color: "white", float: "left", marginLeft: "10px" }}>{text4}</p>
        </Box>

        {planName !== "Free" ?
          <Button variant='contained' sx={{ width: "80%", marginTop: "25px",marginBottom:"25px" }} onClick={checkLogOut}>Subscibe</Button>
          : <div>
            <p style={{ marginLeft: "20px", marginRight: "20px", color: "gold",textAlign:"center" }}>You will only get 25 chats in a day.</p>
            <p style={{ marginLeft: "20px", marginRight: "20px", color: "white",textAlign:"center",marginBottom:"25px" }}>Your free plan will have other limits. So we recommend purchasing a new plan for your better experience.</p>
            
            </div>
          }

        {
          planName === "Gold" ?
            <p style={{ margin: 0, color: "white", backgroundColor: "deeppink",padding:"5px", position: "absolute", top: "0px", right: "0px", borderBottomLeftRadius: "5px" }}>Recomended</p>
            : null
        }

{
          activePlanName === "Active" ?
            <p style={{ margin: 0, color: "white",fontWeight:'bold', backgroundColor: "green", padding: "10px", position: "absolute", top: "5px", left: "5px", borderRadius: "5px" }}>Active</p>
            : null
        }


      </Box>
      {showEmailVerifyAlert ? <EmailVerifyAlert verifyFun={handelVerifyAlertVerifyBtnClick} cancelState={setshowEmailVerifyAlert}/> : null}
      {
        showTextAlert?
        <TextAlert color={'green'} icon={"check"} text={"We have send you an email verification link to your email "}/>
        :null
      }
     

    </Box>
  )
}

export default PricingCard